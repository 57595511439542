/* eslint-disable @typescript-eslint/no-unused-expressions */
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { CustomerList, CustomerRfidData, SingleCustomerAllData, SingleCustomerDataPut } from 'app/core/types/customers.types';
import { ActivityTimelineData, CustomerTimelineReq } from 'app/core/types/notifications.types';
import { Sex } from 'app/core/types/shared.types';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CustomerService {
    customerForm: FormGroup;

    private _data: BehaviorSubject<CustomerList> = new BehaviorSubject(null);
    private _dataActivity: BehaviorSubject<ActivityTimelineData> = new BehaviorSubject(null);
    private _customerData: BehaviorSubject<SingleCustomerAllData> = new BehaviorSubject(null);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _formBuilder: FormBuilder
    ) {
        this.handleError = httpErrorHandler.createHandleError('CustomerService');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<CustomerList> {
        return this._data.asObservable();
    }

    get customerData$(): Observable<SingleCustomerAllData> {
        return this._customerData.asObservable();
    }

    public get dataActivity$(): Observable<ActivityTimelineData> {
        return this._dataActivity.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(pageNumber: number, sortby: string): Observable<CustomerList> {
        sortby ? null : sortby = 'customerPk,desc';
        return this._httpClient.get(`${this.apiBase}/customers?page=${pageNumber}&size=8&sort=${sortby}`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: CustomerList) => {
                this._data.next(response);
            })
        );
    }

    getQueryData(customerSearchPattern: any): Observable<CustomerList> {
        return this._httpClient.post(`${this.apiBase}/customers/query?page=0&size=30&sort=customerPk,desc`, customerSearchPattern).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: CustomerList) => {
                this._data.next(response);
            })
        );
    }

    getSingleCustomer(customerPk: number): Observable<SingleCustomerAllData> {
        return customerPk ? this._httpClient.get(`${this.apiBase}/companyAdmin/customers/${customerPk}`).pipe(
            catchError(this.handleError('getCustomer', [])),
            tap((response: any) => {
                this.getUpdateCustomerForm(response);
                this._customerData.next(response);
            })
        )
            : of({}).pipe(tap(() => this.getCreateUserForm()));
    }

    generateVipCred(customerPk: number, hasVIP: boolean): Observable<HttpStatusCode | any> {
        return hasVIP ?
            this._httpClient.put(`${this.apiBase}/companyAdmin/customers/${customerPk}/vip`, customerPk).pipe(
                catchError(this.handleError('getCustomer', []))
            )
            : this._httpClient.post(`${this.apiBase}/companyAdmin/customers/${customerPk}/vip`, customerPk).pipe(
                catchError(this.handleError('getCustomer', []))
            );
    }

    deleteVipCred(customerPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient.delete(`${this.apiBase}/companyAdmin/customers/${customerPk}/vip`).pipe(
            catchError(this.handleError('getCustomer', []))
        );
    }

    getRFID(customerPk: number): Observable<CustomerRfidData | any> {
        return this._httpClient.get(`${this.apiBase}/companyAdmin/customers/${customerPk}/rfid`).pipe(
            catchError(this.handleError('getRFID', []))
        );
    }

    postRFID(customerPk: number, ocppTag: string): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/customers/${customerPk}/rfid?ocppTagId=${ocppTag}&credit=0`, customerPk).pipe(
            catchError(this.handleError('getCustomer', []))
        );
    }
    deleteRFID(customerPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient.delete(`${this.apiBase}/companyAdmin/customers/${customerPk}/rfid?deleteOcppTag=true`).pipe(
            catchError(this.handleError('getCustomer', []))
        );
    }



    putSingleCustomer(customer: SingleCustomerAllData): Observable<HttpStatusCode | any> {
        const customerPutDto: SingleCustomerDataPut = {
            firstName: customer.firstName,
            lastName: customer.lastName,
            birthDay: customer.birthDay,
            phone: customer.phone,
            note: customer.note,
            sex: customer.sex,
            email: customer.email,
            address: customer.address,
            zipCode: customer.zipCode,
            city: customer.city,
            country: customer.country,
            vip: false
        };

        return this._httpClient.put(`${this.apiBase}/companyAdmin/customers/${customer.customerPk}`, customerPutDto);
    }

    postNewCustomer(customer: SingleCustomerDataPut): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/customers`, customer);
    }

    getCreateUserForm(): void {
        this.customerForm = this._formBuilder.group({
            firstName: [null],
            lastName: [null],
            birthDay: [null],
            phone: [null],
            note: [null],
            sex: [null],
            email: [null, Validators.required],
            address: [null],
            zipCode: [null],
            city: [null],
            country: [null],
            vip: [false],
            RFID: this._formBuilder.group({
                hasRFID: [null],
                OcppTagId: [null],
                Credit: [null],
            })
        });
    }

    getUpdateCustomerForm(customer: SingleCustomerAllData): void {
        this.customerForm = this._formBuilder.group({
            customerPk: [customer?.customerPk],
            firstName: [customer?.firstName],
            lastName: [customer?.lastName],
            birthDay: [customer?.birthDay],
            sex: [customer?.sex, Validators.required],
            phone: [customer?.phone],
            email: [customer?.email, Validators.required],
            note: [customer?.note],
            address: [customer?.address],
            zipCode: [customer?.zipCode],
            city: [customer?.city],
            country: [customer?.country],
            createdAt: [customer?.createdAt],
            uniqueCode: [customer?.uniqueCode],
            customerVip: this._formBuilder.group({
                id: [customer?.customerVip?.id],
                pin: [customer?.customerVip?.pin]
            }),
            RFID: this._formBuilder.group({
                hasRFID: [customer?.rfid],
                OcppTagId: [customer?.customerRfid?.idTag],
                Credit: [customer?.customerRfid?.credit],
            })
        });
    }

    getDiscountForCustomer(vipId: string, vipPin: string): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/public/discountGroup/vip?id=${vipId}&pin=${vipPin}`);
    }

    public getActivityData(date: string, customerPk: number,): Observable<ActivityTimelineData> {
        const userTimeline: CustomerTimelineReq = {
            customerPk: customerPk,
        };
        return this._httpClient.post(`${this.apiBase}/customers/timeline?lastSeenDateTime=${date}&pageSize=8`, userTimeline).pipe(
            catchError(this.handleError('getData', { createdAt: '', activities: [] } as ActivityTimelineData)),
            tap((response: ActivityTimelineData) => {
                this._dataActivity.next(response);
            })
        );
    }

    public getFilteredActivityData(date: string, customerPk: number): Observable<ActivityTimelineData> {
        const userTimeline: CustomerTimelineReq = {
            customerPk: customerPk,
        };
        return this._httpClient.post<ActivityTimelineData>(`${this.apiBase}/customers/timeline?lastSeenDateTime=${date}&pageSize=7`, userTimeline).pipe(
            catchError(this.handleError('getFilteredData', { createdAt: '', activities: [] } as ActivityTimelineData))
        );
    }
}
