import * as moment from 'moment';

/* tslint:disable:max-line-length */
export const users = {
    recentTransactions: [
        {
            id: '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '528651571NT',
            name: 'Morgan Page',
            amount: +1358.75,
            status: '1234',
            date: '2019-10-07T22:22:37.274Z',
        },
        {
            id: '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '421436904YT',
            name: 'Nita Hebert',
            amount: -1042.82,
            status: '123',
            date: '2019-12-18T14:51:24.461Z',
        },
        {
            id: 'ae7c065f-4197-4021-a799-7a221822ad1d',
            transactionId: '685377421YT',
            name: 'Marsha Chambers',
            amount: +1828.16,
            status: '65',
            date: '2019-12-25T17:52:14.304Z',
        },
        {
            id: '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            transactionId: '884960091RT',
            name: 'Charmaine Jackson',
            amount: +1647.55,
            status: '98',
            date: '2019-11-29T06:32:16.111Z',
        },
        {
            id: 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            transactionId: '361402213NT',
            name: 'Maura Carey',
            amount: -927.43,
            status: '11',
            date: '2019-11-24T12:13:23.064Z',
        },
    ],
    schedule: {
        today: [
            {
                title: 'Grupni ugovor XY ističe za 5 dana',
                time: 'prije 32 minute',
            },
            {
                title: 'Korisnik A je najveći klijent u proteklih 6 mjeseci',
                time: '10:30 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
        ],
        tomorrow: [
            {
                title: 'Grupni ugovor XY ističe za 5 dana',
                time: 'prije 32 minute',
            },
            {
                title: 'Korisnik A je najveći klijent u proteklih 6 mjeseci',
                time: '10:30 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
            {
                title: 'Korisnik D konstantno šalje loše ocjene aplikacije',
                time: '11:00 AM',
            },
        ],
    },
    conversions: {
        amount: 4123,
        labels: [
            moment().subtract(47, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(40, 'days').format('DD MMM'),
            moment().subtract(39, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(32, 'days').format('DD MMM'),
            moment().subtract(31, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') +
            ' - ' +
            moment().format('DD MMM'),
        ],
        series: [
            {
                name: 'Conversions',
                data: [4412, 4345, 4541, 4677, 4322, 4123],
            },
        ],
    },
    impressions: {
        amount: 46085,
        labels: [
            moment().subtract(31, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') +
            ' - ' +
            moment().format('DD MMM'),
        ],
        series: [
            {
                name: 'Impressions',
                data: [11577, 11441, 11544, 11523],
            },
        ],
    },
    visits: {
        amount: 62083,
        labels: [
            moment().subtract(31, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(24, 'days').format('DD MMM'),
            moment().subtract(23, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(16, 'days').format('DD MMM'),
            moment().subtract(15, 'days').format('DD MMM') +
            ' - ' +
            moment().subtract(8, 'days').format('DD MMM'),
            moment().subtract(7, 'days').format('DD MMM') +
            ' - ' +
            moment().format('DD MMM'),
        ],
        series: [
            {
                name: 'Visits',
                data: [15521, 15519, 15522, 15521],
            },
        ],
    },
};
