/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/core/http/http-error-handler.service';
import { AddressCategories, AddressesCategory, Category } from 'app/core/types/locations.types';
import { PriceTariffs } from 'app/core/types/tariffs.types';
import { BehaviorSubject, Observable, ReplaySubject, catchError, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class CategoryService {
    private _categories: BehaviorSubject<AddressCategories> = new BehaviorSubject(null);
    private _deletedCategories: BehaviorSubject<AddressCategories> = new BehaviorSubject(null);
    private _addressesCategory: ReplaySubject<AddressesCategory> = new ReplaySubject<AddressesCategory>(1);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('CategoriesService');
    }

    public get categories$(): Observable<AddressCategories> {
        return this._categories.asObservable();
    }

    public get deletedCategories$(): Observable<AddressCategories> {
        return this._deletedCategories.asObservable();
    }

    public get addressesCategory$(): Observable<AddressesCategory> {
        return this._addressesCategory.asObservable();
    }

    public getCategories(): Observable<AddressCategories> {
        return this._httpClient.get(`${this.apiBase}/addresses/categories`).pipe(
            catchError(this.handleError('getCategories', [])),
            tap((response: AddressCategories) => {
                this._categories.next(response);
            })
        );
    }

    public getDeletedCategories(): Observable<AddressCategories> {
        return this._httpClient.get(`${this.apiBase}/addresses/categories/deleted`).pipe(
            catchError(this.handleError('getAllData', [])),
            tap((response: AddressCategories) => {
                this._deletedCategories.next(response);
            })
        );
    }

    public postNewCategory(newCategory: Category): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/addresses/categories`, newCategory).pipe(
            catchError(this.handleError('postNewCategory', []))
        );
    }

    public postNewTariff(newTariff: PriceTariffs): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/priceTariffs`, newTariff);
    }

    public putNewTariff(priceTariffPk: number, newTariff: { acPowerPrice: number; dcUpTo50KwPowerPrice: number; dcAbove50KwPowerPrice: number }): Observable<any> {
        return this._httpClient.put(`${this.apiBase}/priceTariffs/prices/${priceTariffPk}`, newTariff).pipe(
            catchError(this.handleError('postNewCategory', []))
        );
    }

    public putExistingCategory(newCategory: { name: string }, addressCategoryPk: number): Observable<any> {
        return this._httpClient.put(`${this.apiBase}/addresses/categories/${addressCategoryPk}`, newCategory).pipe(
            catchError(this.handleError('putExistingCategory', []))
        );
    }

    public undeleteCategory(addressCategoryPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/addresses/categories/undelete/${addressCategoryPk}`, null).pipe(
            catchError(this.handleError('undeleteCategory', []))
        );
    }

    public updateBatchLocationsCategories(data: AddressesCategory): Observable<any> {
        return this._httpClient
            .patch(`${this.apiBase}/addresses/batch/category`, data)
            .pipe(catchError(this.handleError('AdressesCategory', [])));
    }
}
