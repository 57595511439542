import { Injectable } from '@angular/core';
import {
    Resolve
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { PaymentService } from '../payment.service';

@Injectable({
    providedIn: 'root',
})
export class PaymentResultSuccessResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _paymentService: PaymentService,
        private _translocoService: TranslocoService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(
    ): any {
        this._translocoService.setActiveLang(this._paymentService.selectedLang);
    }
}
