/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PaginationTracker } from 'app/core/types/shared.types';
import { Subject, takeUntil } from 'rxjs';
import { ReqRespModalComponent } from './req-resp-modal/req-resp-modal.component';
import { SessionDetailsService } from './session-details.service';

@Component({
  selector: 'app-session-details-modal',
  templateUrl: './session-details-modal.component.html',
  styleUrls: ['./session-details-modal.component.scss']
})
export class SessionDetailsModalComponent implements OnInit, OnDestroy {
  filterFormData: FormGroup;
  messageTypes$: any;
  filtered: boolean = false;
  pageNumber: number = 0;
  pageable: PaginationTracker;
  recentTransactionsDataSource: MatTableDataSource<any> =
    new MatTableDataSource();
  recentTransactionsTableColumns: string[] = [
    'chargepointId',
    'direction',
    'messageType',
    'timestamp',
    'raw'
  ];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _sessionDetailsService: SessionDetailsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SessionDetailsModalComponent>,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.filterFormData = this._formBuilder.group({
      messageTypes: [null],
      direction: [null]
    });

    this._sessionDetailsService.sessionDetailsdata$.pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.recentTransactionsDataSource = data.content;
      this.pageable = {
        offset: data?.pageable?.offset,
        numberOfElements: data?.numberOfElements,
        totalElements: data?.totalElements,
        totalPages: data?.totalPages
      };
      this._changeDetectorRef.detectChanges();
    });

    this._sessionDetailsService.getMessageTypes().pipe(takeUntil(this._unsubscribeAll)).subscribe((data) => {
      this.messageTypes$ = data;
    });
  }

  applyFilter(): void {
    this.filtered = true;

    const getFilterValues = this.filterFormData.getRawValue();

    this._sessionDetailsService.getSessionFilterData(0, this.data, ({
      direction: getFilterValues.direction,
      messageTypes: getFilterValues.messageTypes ? [
        getFilterValues.messageTypes
      ] :
        []
    })).pipe(takeUntil(this._unsubscribeAll)).subscribe();
  }
  resetFilter(): void {
    this.filtered = false;
    this.filterFormData.reset();
    this._sessionDetailsService.getSessionDetailsData(0, this.data).pipe(takeUntil(this._unsubscribeAll)).subscribe();
  }


  changePage(action: number) {
    const filterData: any = this.filterFormData.getRawValue();

    if (action === 0) {
      this.pageNumber = 0;
    }
    else if (this.pageable.totalPages > 1 && action !== 0) {
      if (action === 1 && this.pageNumber !== 0) {
        this.pageNumber = this.pageNumber - 1;
      }
      else if (action === 2 && this.pageNumber < this.pageable.totalPages - 1) {
        this.pageNumber = this.pageNumber + 1;
      }
      else if (action === 3) {
        this.pageNumber = this.pageable.totalPages - 1;
      }
    }

    this.filtered ?
      this._sessionDetailsService.getSessionFilterData(this.pageNumber, this.data, ({
        direction: filterData.direction,
        messageTypes: filterData.messageTypes ? [
          filterData.messageTypes
        ] :
          []
      })).subscribe() :
      this._sessionDetailsService.getSessionDetailsData(this.pageNumber, this.data).subscribe();
  }

  openRequestResponseModal(sessionDetails: any) {
    this.dialog.open(ReqRespModalComponent, {
      width: '70%',
      height: '70%',
      autoFocus: false,
      data: ({
        request: JSON.parse(sessionDetails.request),
        requestTime: sessionDetails.requestTime,
        response: JSON.parse(sessionDetails.response),
        responseTime: sessionDetails.responseTime
      }),
      panelClass: 'diagnostics'
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
