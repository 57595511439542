/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationMessageComponent } from 'app/core/shared/components/notification/notification-message.component';
import { DeleteTypes, NOTIFICATION_DURATION_MS, NotificationStatus } from 'app/core/types/shared.types';
import { ChargersService } from 'app/modules/admin/chargers/chargers.service';
import { CompanyService } from 'app/modules/admin/company/company.service';
import { CustomerService } from 'app/modules/admin/customers/customers.service';
import { LocationService } from 'app/modules/admin/locations/location/location.service';
import { LocationsService } from 'app/modules/admin/locations/locations.service';
import { CategoryService } from 'app/modules/admin/settings/categories/categories.service';
import { SettingsDaysOffService } from 'app/modules/admin/settings/days-off/days-off.service';
import { DiscountService } from 'app/modules/admin/settings/discounts/discounts.service';
import { TariffsService } from 'app/modules/admin/settings/tariffs/tariffs.service';
import { TeamService } from 'app/modules/admin/settings/team/team.service';
import { Subject, forkJoin, takeUntil } from 'rxjs';
import { DeleteModalService } from './delete-modal.service';

@Component({
    selector: 'app-charger-delete-modal',
    templateUrl: './delete-modal.component.html',
    styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit, OnDestroy {
    chargerForm: FormGroup;
    read: string;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _deleteModalaService: DeleteModalService,
        private dialogRef: MatDialogRef<DeleteModalComponent>,
        private _snackBar: MatSnackBar,
        private _transloco: TranslocoService,
        private _customerService: CustomerService,
        private _locationsService: LocationsService,
        private _locationService: LocationService,
        private _categoryService: CategoryService,
        private _chargersService: ChargersService,
        private _discountService: DiscountService,
        private _teamService: TeamService,
        private _tariffService: TariffsService,
        private _daysOffService: SettingsDaysOffService,
        private _compaynService: CompanyService
    ) { }

    ngOnInit(): void {
        this.read = this.reading();
    }

    submit(): void {
        switch (this.data.action) {
            case DeleteTypes.location:
                this._deleteModalaService.deleteSingleLocation(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.expenseGroupSettings:
                this._deleteModalaService.deleteExpenseGroup(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.expenseGroup:
                this._deleteModalaService.deleteExpenseGroup(this.data.value.pk).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.expenseTariff:
                this._deleteModalaService.deleteExpenseTariff(this.data.value.pk).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.businessPremise:
                this._deleteModalaService.deleteBusinessPremise(this.data.value.pk).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.charger:
                this._deleteModalaService.deleteSingleChargepoint(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.customer:
                this._deleteModalaService.deleteSingleCustomer(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.category:
                this._deleteModalaService.deleteSingleCategory(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.discount:
                this._deleteModalaService.deleteSingleDiscount(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.team:
                this._deleteModalaService.deleteSingleMember(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.tariff:
                this._deleteModalaService.deleteSingleTariff(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.dayOffGroup:
                this._deleteModalaService.deleteDayOffGroup(this.data.value).pipe(takeUntil(this._unsubscribeAll))
                    .subscribe({
                        next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                        error: () => this.handleFormError(NotificationStatus.error),
                    });
                break;
            case DeleteTypes.dayOffDay:
                this._deleteModalaService.deleteDayOffDay(this.data.value.day).pipe(takeUntil(this._unsubscribeAll))
                    .subscribe({
                        next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                        error: () => this.handleFormError(NotificationStatus.error),
                    });
                break;
            case DeleteTypes.workHours:
                this._deleteModalaService.deleteWorkHours(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case DeleteTypes.company:
                this._deleteModalaService.deleteCompany(this.data.value).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                }); break;
            case DeleteTypes.companyCustomer:
                this._deleteModalaService.deleteCompanyCustomer(this.data.value.email).pipe(takeUntil(this._unsubscribeAll)).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                }); break;
        }
    }

    handleFormError(status: NotificationStatus): void {
        this.showSnackBar(status);
    }

    handleAfterFormSubmit(status: NotificationStatus): void {
        this.showSnackBar(status);
        switch (this.data.action) {
            case DeleteTypes.location:
                this._locationsService.getData(0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.expenseGroupSettings:
                this._locationService.getExpenseGroups().pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.expenseGroup:
                this._locationService.getLocationData(this.data.value.id).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.expenseTariff:
                this._locationService.getExpenseTariffGroupDetails(this.data.value.id).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.businessPremise:
                this._locationService.getLocationData(this.data.value.id).subscribe();
                break;
            case DeleteTypes.charger:
                this._chargersService.getData(0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.customer:
                this._customerService.getData(0, null).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.category:
                forkJoin([
                    this._categoryService.getCategories(),
                    this._categoryService.getDeletedCategories(),
                ]).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.discount:
                this._discountService.getDiscountData(0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.team:
                forkJoin([
                    this._teamService.getQueryData(0, true),
                    this._teamService.getQueryData(0, false),
                ]).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.tariff:
                this._tariffService.getData(0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.dayOffGroup:
                this._daysOffService.getNonWorkingDayGroups(0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.dayOffDay:
                this._daysOffService.getNonWorkingDayGroupDetails(this.data.value.group).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.workHours:
                this._locationService.mapsForm.patchValue({ daysWorkingHours: null });
                break;
            case DeleteTypes.company:
                this._compaynService.getQueryData({
                    businessEntityPks: null,
                    businessNames: null,
                    businessName: null,
                    deleted: false
                }, 0).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
            case DeleteTypes.companyCustomer:
                forkJoin([
                    this._compaynService.getQueryData({ businessEntityPks: [this.data.value.pk] }, 0),
                    this._customerService.getQueryData({ businessEntityPk: this.data.value.pk })
                ]).pipe(takeUntil(this._unsubscribeAll)).subscribe();
                break;
        }
        setTimeout(() => this.dialogRef.close(), NOTIFICATION_DURATION_MS);
    }

    showSnackBar(status: NotificationStatus): void {
        this._snackBar.openFromComponent(NotificationMessageComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
                message:
                    status === NotificationStatus.error
                        ? this._transloco.translate('Error')
                        : this._transloco.translate(this.read + '.Success'),
                status: status,
            },
            duration: NOTIFICATION_DURATION_MS,
        });
    }

    reading() {
        switch (this.data.action) {
            case DeleteTypes.location:
                return 'Locations.Delete';
            case DeleteTypes.expenseGroupSettings:
                return 'Locations.Details.Edit.Expense.DeleteGroup';
            case DeleteTypes.expenseGroup:
                return 'Locations.Details.Edit.Expense.DeleteGroup';
            case DeleteTypes.expenseTariff:
                return 'Locations.Details.Edit.Expense.DeleteTariff';
            case DeleteTypes.businessPremise:
                return 'Locations.Details.Edit.BusinessPremise.Delete';
            case DeleteTypes.charger:
                return 'Chargers.Delete';
            case DeleteTypes.customer:
                return 'Customer.Delete';
            case DeleteTypes.category:
                return 'Setting.Categories.Delete';
            case DeleteTypes.discount:
                return 'Setting.Discounts.Delete';
            case DeleteTypes.team:
                return 'Setting.Team.Delete';
            case DeleteTypes.tariff:
                return 'Setting.Tariffs.Delete';
            case DeleteTypes.dayOffGroup:
                return 'Setting.DaysOff.Delete.Gruop';
            case DeleteTypes.dayOffDay:
                return 'Setting.DaysOff.Delete.Day';
            case DeleteTypes.workHours:
                return 'Locations.Details.Edit.WorkingHours.Delete';
            case DeleteTypes.company:
                return 'Company.Delete';
            case DeleteTypes.companyCustomer:
                return 'Company.Edit.Delete';
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
