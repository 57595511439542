import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-req-resp-modal',
  templateUrl: './req-resp-modal.component.html',
  styleUrls: ['./req-resp-modal.component.scss']
})
export class ReqRespModalComponent implements OnInit {
  preeety: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ReqRespModalComponent>
  ) { }

  ngOnInit(): void {
  }

}
