/* tslint:disable:max-line-length */
import { FuseNavigationItem } from '@fuse/components/navigation';

const navigation: any[] = [
    {
        id: 'home',
        title: 'Naslovnica',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/home',
    },
    {
        id: 'finance',
        title: 'Analitika',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/analytics',
    },
    {
        id: 'invoice',
        title: 'Financije',
        type: 'basic',
        icon: 'heroicons_outline:credit-card',
        link: '/finance',
    },
    {
        id: 'chargers',
        title: 'Punionice',
        type: 'basic',
        icon: 'heroicons_outline:lightning-bolt',
        link: '/chargers',
    },
    {
        id: 'roaming',
        title: 'Roming',
        type: 'basic',
        icon: 'heroicons_outline:paper-airplane',
        link: '/roaming',
    },
    {
        id: 'contacts',
        title: 'Kontakti',
        type: 'collapsable',
        icon: 'heroicons_outline:identification',
        children: [
            {
                id: 'customers',
                title: 'Korisnici',
                type: 'basic',
                icon: 'heroicons_outline:user-group',
                link: '/customers',
            },
            {
                id: 'company',
                title: 'Kompanije',
                type: 'basic',
                icon: 'heroicons_outline:office-building',
                link: '/business',
            },
        ]
    },
    // {
    //     id: 'reports',
    //     title: 'Izvještaji',
    //     type: 'basic',
    //     icon: 'heroicons_outline:document-report',
    //     link: '/reports',
    // },
    {
        id: 'locations',
        title: 'Lokacije',
        type: 'basic',
        icon: 'heroicons_outline:map',
        link: '/locations',
    },
    {
        id: 'session',
        title: 'Sessions',
        type: 'basic',
        icon: 'heroicons_outline:document-text',
        link: '/session',
    },
    {
        id: 'notifications',
        title: 'Notifications',
        type: 'basic',
        icon: 'heroicons_outline:bell-alert',
        link: '/notifications',
    },
    {
        id: 'price-calendar',
        title: 'Price calendar',
        type: 'basic',
        icon: 'heroicons_outline:calendar-days',
        link: '/price-calendar',
    },
];

export const defaultNavigation: FuseNavigationItem[] = navigation;
export const compactNavigation: FuseNavigationItem[] = navigation;
export const futuristicNavigation: FuseNavigationItem[] = navigation;
export const horizontalNavigation: FuseNavigationItem[] = navigation;
