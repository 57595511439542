/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthUtils } from 'app/core/auth/auth.utils';
import { UserService } from 'app/core/user/user.service';
import jwtDecode from 'jwt-decode';
import { Observable, catchError, of, switchMap, throwError } from 'rxjs';
@Injectable()
export class AuthService {
    authenticated: boolean = false;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _userService: UserService,
        @Inject('API_BASE_URL') private apiBase: string,
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    get accessToken(): string {
        return localStorage.getItem('accessToken') ?? '';
    }

    set accessToken(token: string) {
        localStorage.setItem('accessToken', token);
    }

    get refreshToken(): string {
        return localStorage.getItem('refreshToken') ?? '';
    }

    set refreshToken(token: string) {
        localStorage.setItem('refreshToken', token);
    }

    // get UID(): string {
    //     return localStorage.getItem('UID') ?? '';
    // }

    // set UID(id: string) {
    //     localStorage.setItem('UID', id);
    // }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/public/users/auth/forgot-password?username=${email}`, email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(newPasswordDto: any, user: boolean): Observable<any> {
        return user ? this._httpClient.post(`${this.apiBase}/public/users/auth/reset-password`, newPasswordDto)
            : this._httpClient.post(`${this.apiBase}/publicMobile/customers/auth/reset-password`, newPasswordDto);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(credentials: { username: string; password: string }): Observable<any> {
        // Throw error, if the user is already logged in
        if (this.authenticated) {
            return throwError('User is already logged in.');
        }

        return this._httpClient.post(`${this.apiBase}/public/users/auth/login`, credentials).pipe(
            switchMap((response: any) => {
                // Store the access token in the local storage
                this.accessToken = response.accessToken;

                // Store the refresh token in the local storage
                this.refreshToken = response.refreshToken;

                // Store user id for fetching data
                // this.UID = this.getDecodedAccessToken(response.accessToken);

                // // Store the user on the user service
                // this._userService.user = response.user;
                // this._userService.user = {
                //     userPk: 555333,
                //     firstName: 'testadmin',
                //     email: 'test@test.com',
                //     avatar: 'src/assets/images/avatars/male-02.jpg',
                // } as User;

                // Return a new observable with the response
                return of(response);
            })
        );
    }

    //Used to decode the jwt
    getDecodedAccessToken(token: string): any {
        try {
            return jwtDecode(token);
        } catch (Error) {
            return null;
        }
    }

    /**
     * Sign in using the access token
     */
    signInUsingToken(): Observable<any> {
        // Renew token
        return this._httpClient
            .post(`${this.apiBase}/public/users/auth/token/refresh`, {
                refreshToken: this.refreshToken,
            })
            .pipe(
                catchError(() =>
                    // Return false
                    of(false)
                ),
                switchMap((response: any) => {
                    // Store the access token in the local storage
                    this.accessToken = response.accessToken;

                    // Store the user on the user service
                    // this._userService.user = response.user;

                    // Return true
                    return of(true);
                })
            );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        // Remove the access token from the local storage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        // localStorage.removeItem('UID');


        // Set the authenticated flag to false
        // this.authenticated = false;

        // Return the observable
        return of(true);
    }

    /**
     * Sign up
     *
     * @param user
     */
    signUp(user: {
        name: string;
        email: string;
        password: string;
        company: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/sign-up', user);
    }

    /**
     * Unlock session
     *
     * @param credentials
     */
    unlockSession(credentials: {
        email: string;
        password: string;
    }): Observable<any> {
        return this._httpClient.post('api/auth/unlock-session', credentials);
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {

        if (this.accessToken === 'undefined' || this.refreshToken === 'undefined') {
            return of(false);
        }

        // Check the access token expire date
        if (this.accessToken && !AuthUtils.isTokenExpired(this.accessToken)) {
            return of(true);
        }

        if (this.refreshToken && !AuthUtils.isTokenExpired(this.refreshToken)) {
            return this.signInUsingToken();
        }

        // If the access token exists and it didn't expire, sign in using it
        return of(false);
    }
}
