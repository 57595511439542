<mat-dialog-content *transloco="let t; read: 'Session.Details'" class="min-w-4/5 min-h-full h-full flex flex-col">
    <!-- Header -->
    <div class="p-2 flex justify-between">
        <div class="justify-start w-full">
            <div class="text-2xl font-bold text-gray-500">{{ t('Title') }}</div>
        </div>

        <div class="flex justify-end">
            <button mat-icon-button aria-label="close dialog" mat-dialog-close>
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <!-- Filters -->
    <form class="flex w-full p-2 gap-3" [formGroup]="filterFormData">
        <div class="flex flex-col flex-auto overflow-hidden">
            <mat-form-field floatLabel="always" class="fuse-mat-rounded w-full">
                <mat-select [formControlName]="'messageTypes'" [placeholder]="t('MessageType')">
                    <div class="flex flex-col max-h-50">
                        <div class="overflow-auto">
                            <mat-option *ngFor="let messageType of messageTypes$" [value]="messageType"
                                class="rounded-full h-8 p-1 pl-4 my-1">
                                {{ messageType }}
                            </mat-option>
                        </div>
                    </div>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="flex flex-col flex-auto overflow-hidden">
            <mat-form-field floatLabel="always" class="fuse-mat-rounded w-full">
                <mat-select [formControlName]="'direction'" [placeholder]="t('Direction')">
                    <ng-container>
                        <mat-option value="FROM_CSS" class="rounded-full h-8 p-1 pl-4 my-1">
                            {{ t('FROM_CSS') }}
                        </mat-option>
                        <mat-option value="TO_CSS" class="rounded-full h-8 p-1 pl-4 my-1">
                            {{ t('TO_CSS') }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <button (click)="applyFilter()" mat-flat-button [matTooltip]="t('Filter')">
            <mat-icon [svgIcon]="'heroicons_outline:search'"></mat-icon>
        </button>

        <button (click)="resetFilter()" mat-flat-button [matTooltip]="t('ResetFilter')">
            <mat-icon [svgIcon]="'heroicons_outline:document'"></mat-icon>
        </button>
    </form>

    <!-- Table -->
    <div class="grid grid-cols-1 gap-8 w-full mt-4 h-full min-h-90">
        <!-- Recent sessions table -->
        <div class="xl:col-span-2 flex flex-col flex-auto bg-card shadow rounded-3xl overflow-hidden">
            <div class="overflow-x-auto flex flex-col justify-between h-full mx-6">
                <div>
                    <table class="w-full bg-transparent" mat-table matSort [dataSource]="recentTransactionsDataSource"
                        [trackBy]="trackByFn" #recentTransactionsTable>
                        <!-- CBID -->
                        <ng-container matColumnDef="chargepointId">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-lg text-gray-500 font-bold">{{ t('CBID') }}</span>
                            </th>
                            <td mat-cell *matCellDef="let transaction">
                                <span class="text-gray-500">{{ transaction.chargeBoxId }}</span>
                            </td>
                        </ng-container>

                        <!-- Direction -->
                        <ng-container matColumnDef="direction">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-lg text-gray-500 font-bold">{{ t('Direction') }}</span>
                            </th>
                            <td mat-cell *matCellDef="let transaction">
                                <span class="pr-6 whitespace-nowrap">
                                    <span class="text-gray-500">{{ transaction.direction === 'TO_CSS' ? t('TO_CSS') :
                                        t('FROM_CSS')}}</span>
                                </span>
                            </td>
                        </ng-container>

                        <!-- Message type -->
                        <ng-container matColumnDef="messageType">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-lg text-gray-500 font-bold">{{ t('MessageType') }}</span>
                            </th>
                            <td mat-cell *matCellDef="let transaction">
                                <span class="pr-6 whitespace-nowrap">
                                    <span class="text-gray-500">{{ transaction.messageType }}</span>
                                </span>
                            </td>
                        </ng-container>

                        <!-- timestamp -->
                        <ng-container matColumnDef="timestamp">
                            <th mat-header-cell *matHeaderCellDef>
                                <span class="text-lg text-gray-500 font-bold">{{ t('Timestamp') }}</span>
                            </th>
                            <td mat-cell *matCellDef="let transaction">
                                <span>
                                    <div class="flex gap-1 justify-start whitespace-nowrap">
                                        <mat-icon class="icon-size-5 text-gray-300"
                                            [svgIcon]="'heroicons_outline:calendar'">
                                        </mat-icon>
                                        <span class="text-gray-500 ">{{ transaction.requestTime | date: 'dd.MM.yyyy'
                                            }}</span>
                                    </div>
                                    <div class="flex gap-1 justify-start whitespace-nowrap">
                                        <mat-icon class="icon-size-5 text-gray-300"
                                            [svgIcon]="'heroicons_outline:clock'"></mat-icon>
                                        <span class="text-gray-500 ">{{transaction.requestTime | date: 'H:mm'}}</span>
                                    </div>
                                </span>
                            </td>
                        </ng-container>

                        <!-- Raw -->
                        <ng-container matColumnDef="raw">
                            <th class="text-center" mat-header-cell *matHeaderCellDef>
                                <span class="text-lg text-gray-500 font-bold">{{ t('Raw') }}</span>
                            </th>
                            <td mat-cell *matCellDef="let transaction">
                                <div class="w-full flex justify-center">
                                    <button (click)="openRequestResponseModal(transaction)" mat-button
                                        class="flex items-center text-gray-500 w-44 h-9 gap-2" mat-flat-button>
                                        <mat-icon class="icon-size-6 mr-1 text-gray-500"
                                            [svgIcon]="'heroicons_outline:code-bracket-square'"></mat-icon>
                                    </button>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="recentTransactionsTableColumns"></tr>
                        <tr class="order-row cursor-default h-16" mat-row
                            *matRowDef="let row; columns: recentTransactionsTableColumns">
                        </tr>
                        <!-- Row shown when there is no matching data after applied filter -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell text-center pt-2" colspan="8">
                                <mat-icon [svgIcon]="'heroicons_outline:document'"></mat-icon>
                                <p class="mat-cell text-center">
                                    {{ t('NoData') }}
                                </p>
                            </td>
                        </tr>
                    </table>
                </div>

                <div>
                    <div class="row position-absolute bottom-0 align-self-center text-center py-4">
                        <div class="flex items-center gap-2 justify-center">
                            <button (click)="changePage(0)">
                                <mat-icon
                                    [ngStyle]="pageable.offset === 0 ?  {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                    [svgIcon]="'heroicons_outline:chevron-double-left'"></mat-icon>
                            </button>
                            <button (click)="changePage(1)">
                                <mat-icon
                                    [ngStyle]="pageable.offset === 0 ?  {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                    [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
                            </button>
                            <div class="text-gray-500 w-fit  justify-center">
                                {{pageable?.offset + 1}}-{{pageable?.offset + pageable?.numberOfElements}}
                                {{ t('of') }} {{pageable?.totalElements}}
                            </div>
                            <button (click)="changePage(2)">
                                <mat-icon
                                    [ngStyle]="(pageable.offset + pageable.numberOfElements) === pageable?.totalElements ? {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                    [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
                            </button>
                            <button (click)="changePage(3)">
                                <mat-icon
                                    [ngStyle]="(pageable.offset + pageable.numberOfElements) === pageable?.totalElements ? {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                    [svgIcon]="'heroicons_outline:chevron-double-right'"></mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>