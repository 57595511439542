<ng-container *transloco="let t; read: 'Setting.Configuration.Opera'">
    <form [formGroup]="operaTenantForm">
        <ng-container [formGroupName]="'configuration'">
            <div class="flex flex-col text-gray-500 text-lg bg-white rounded-3xl p-6 gap-4 w-full mb-10">
                <mat-label class="font-bold text-2xl text-gray-500">
                    {{ t('Title')?.toUpperCase() }}
                </mat-label>

                <!-- EndpointAddress -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('EndpointAddress') }}</mat-label>
                    <input [formControlName]="'endpointAddress'" matInput>
                </mat-form-field>

                <!-- User data -->
                <div class="flex flex-col sm:flex-row gap-4">
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('Username') }}</mat-label>
                        <input [formControlName]="'username'" matInput>
                    </mat-form-field>

                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('Password') }}</mat-label>
                        <input [formControlName]="'password'" type="password" autocomplete="new-password"
                            [spellcheck]="false" matInput #passwordField>
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <!-- Account -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('Account') }}</mat-label>
                    <input [formControlName]="'account'" matInput />
                </mat-form-field>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- UserId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('UserId') }}</mat-label>
                        <input [formControlName]="'userId'" matInput />
                    </mat-form-field>

                    <!-- PrimaryLangId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('LanguageId') }}</mat-label>
                        <input [formControlName]="'primaryLangId'" matInput />
                    </mat-form-field>
                </div>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- OriginEntityId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('OriginEntityId') }}</mat-label>
                        <input [formControlName]="'originEntityId'" matInput />
                    </mat-form-field>

                    <!-- OriginSystemType -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('OriginSystemType') }}</mat-label>
                        <input [formControlName]="'originSystemType'" matInput />
                    </mat-form-field>
                </div>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- DestinationEntityId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('DestinationEntityId') }}</mat-label>
                        <input [formControlName]="'destinationEntityId'" matInput />
                    </mat-form-field>

                    <!-- DestinationSystemType -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('DestinationSystemType') }}</mat-label>
                        <input [formControlName]="'destinationSystemType'" matInput />
                    </mat-form-field>
                </div>

                <!-- LongInfo -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('LongInfo') }}</mat-label>
                    <input [formControlName]="'longInfo'" matInput />
                </mat-form-field>

                <!-- ShortInfo -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('ShortInfo') }}</mat-label>
                    <input [formControlName]="'shortInfo'" matInput />
                </mat-form-field>

                <!-- Article -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('Article') }}</mat-label>
                    <input [formControlName]="'article'" matInput />
                </mat-form-field>
            </div>
        </ng-container>

        <!-- Divider -->
        <!-- <div class="my-5 border-t"></div> -->

        <ng-container [formGroupName]="'demoConfiguration'">
            <div class="flex flex-col text-gray-500 text-lg bg-white rounded-3xl p-6 gap-4 w-full mb-10">
                <mat-label class="font-bold text-2xl">
                    {{ t('DemoTitle')?.toUpperCase() }}
                </mat-label>

                <!-- EndpointAddress -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('EndpointAddress') }}</mat-label>
                    <input [formControlName]="'endpointAddress'" matInput>
                </mat-form-field>

                <!-- User data -->
                <div class="flex flex-col sm:flex-row gap-4">
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('Username') }}</mat-label>
                        <input [formControlName]="'username'" matInput>
                    </mat-form-field>

                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('Password') }}</mat-label>
                        <input [formControlName]="'password'" type="password" autocomplete="new-password"
                            [spellcheck]="false" matInput #passwordField>
                        <button mat-icon-button type="button"
                            (click)="passwordField.type === 'password' ? (passwordField.type = 'text') : (passwordField.type = 'password')"
                            matSuffix>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'password'"
                                [svgIcon]="'heroicons_solid:eye'"></mat-icon>
                            <mat-icon class="icon-size-5" *ngIf="passwordField.type === 'text'"
                                [svgIcon]="'heroicons_solid:eye-off'"></mat-icon>
                        </button>
                    </mat-form-field>
                </div>

                <!-- Account -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('Account') }}</mat-label>
                    <input [formControlName]="'account'" matInput />
                </mat-form-field>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- UserId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('UserId') }}</mat-label>
                        <input [formControlName]="'userId'" matInput />
                    </mat-form-field>

                    <!-- PrimaryLangId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('LanguageId') }}</mat-label>
                        <input [formControlName]="'primaryLangId'" matInput />
                    </mat-form-field>
                </div>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- OriginEntityId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('OriginEntityId') }}</mat-label>
                        <input [formControlName]="'originEntityId'" matInput />
                    </mat-form-field>

                    <!-- OriginSystemType -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('OriginSystemType') }}</mat-label>
                        <input [formControlName]="'originSystemType'" matInput />
                    </mat-form-field>
                </div>

                <div class="flex flex-col sm:flex-row gap-4">
                    <!-- DestinationEntityId -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('DestinationEntityId') }}</mat-label>
                        <input [formControlName]="'destinationEntityId'" matInput />
                    </mat-form-field>

                    <!-- DestinationSystemType -->
                    <mat-form-field class="fuse-mat-rounded w-full">
                        <mat-label>{{ t('DestinationSystemType') }}</mat-label>
                        <input [formControlName]="'destinationSystemType'" matInput />
                    </mat-form-field>
                </div>

                <!-- LongInfo -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('LongInfo') }}</mat-label>
                    <input [formControlName]="'longInfo'" matInput />
                </mat-form-field>

                <!-- ShortInfo -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('ShortInfo') }}</mat-label>
                    <input [formControlName]="'shortInfo'" matInput />
                </mat-form-field>

                <!-- Article -->
                <mat-form-field class="fuse-mat-rounded w-full">
                    <mat-label>{{ t('Article') }}</mat-label>
                    <input [formControlName]="'article'" matInput />
                </mat-form-field>
            </div>
        </ng-container>
    </form>

    <div class="flex items-center gap-4 mb-6 justify-center">
        <button (click)="onCancel()" mat-stroked-button class="w-72" type="button">{{ t('Cancel') }}</button>
        <button [disabled]="!operaTenantForm.valid" class=" w-72" mat-flat-button type="button" [color]="'primary'"
            (click)="onSubmit()">
            {{ t('Save') }}
        </button>
    </div>
</ng-container>
