/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Inject, Injectable } from '@angular/core';
import { ConnectorPaymentResponse, WebSocketSendMsg } from 'app/core/types/payment.types';
import { Observable, concatMap, delayWhen, of, retryWhen, shareReplay, take, tap, timer } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

@Injectable({
    providedIn: 'root'
})

export class ChargerStatusWebSocketService {
    private _chargerStatusWebSocket: WebSocketSubject<any>;
    private _sharedWebSocket$: Observable<any>;
    //(this.apiBase.replace(/^http/, 'ws').replace('api/v1', 'websocket/ChargingStatus/'));
    //(window.location.origin.replace(/^http/, 'ws').toString().concat('/ecm/websocket/ChargingStatus/'));
    private openChanelInitDto: WebSocketSendMsg;

    constructor(
        @Inject('WEB_SOCKET_URL') private wsBaseUrl: string
    ) {
    }

    set chargingStatusData(chargingConnectorData: ConnectorPaymentResponse) {
        localStorage.setItem('chargingStatusData', JSON.stringify(chargingConnectorData));
    }

    get chargingStatusData() {
        return JSON.parse(localStorage.getItem('chargingStatusData')) ?? '';
    }

    initializeWebSocket(chargeBoxId: string): Observable<null> {
        this._chargerStatusWebSocket = webSocket<any>(this.wsBaseUrl + chargeBoxId);
        this._sharedWebSocket$ = this._chargerStatusWebSocket.pipe(shareReplay(1));
        return of(null);
    }

    webSocketObservableChecker(webSocketAuthDto: WebSocketSendMsg, chargeboxId: string): Observable<any> {
        if (this._chargerStatusWebSocket?.closed !== false) {
            this.initializeWebSocket(chargeboxId);
        }
        this.sendData(webSocketAuthDto);

        return of(null);
    }

    webSocketReconnectObservableChecker(webSocketAuthDto: WebSocketSendMsg, chargeboxId: string): Observable<any> {
        this.openChanelInitDto = null;
        if (this._chargerStatusWebSocket?.closed !== false) {
            this.initializeWebSocket(chargeboxId);
        }

        this.sendData(webSocketAuthDto);

        return of(null);
    }

    listenWebSocket(webSocketAuthDto: WebSocketSendMsg, chargeboxId: string): Observable<ConnectorPaymentResponse | null> {
        return this._sharedWebSocket$.pipe(
            retryWhen(errors =>
                errors.pipe(
                    // tap(val => console.log('[WebSocket] trying to reconnect', val)),
                    delayWhen(_ => timer(2000)),
                    take(7),
                    concatMap(() => this.webSocketReconnectObservableChecker(webSocketAuthDto, chargeboxId))
                )),
            tap((response: ConnectorPaymentResponse) => {
                if (response?.connectorStatuses) {
                    response.connectorStatuses.forEach((singleItem) => {
                        if (singleItem.jsonAndDisconnected === true) {
                            singleItem.status = 'Offline';
                        }
                        else if (singleItem.status === 'Preparing') {
                            singleItem.status = 'Connected';
                        }
                        singleItem.status = singleItem.status.replace('_', ' ');
                    });

                    response.connectorStatuses.sort((connectorA, connectorB) => (connectorA.connectorId < connectorB.connectorId ? -1 : 1));

                    this.chargingStatusData = response;
                }
            })
        );
    }

    sendData(webSocketMsgData: WebSocketSendMsg): any {
        if (webSocketMsgData?.identifier === this.openChanelInitDto?.identifier && webSocketMsgData?.state === this.openChanelInitDto?.state) {
            return;
        }
        this.openChanelInitDto = webSocketMsgData;
        this._chargerStatusWebSocket.next(webSocketMsgData);
    }

    closeConnection() {
        this._chargerStatusWebSocket.complete();
        this._chargerStatusWebSocket.unsubscribe();
        this.openChanelInitDto = null;
    }

    deleteLocalConnectorStatusData(): void {
        localStorage.removeItem('chargingStatusData');
    }
}
