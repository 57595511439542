import { HttpClient, HttpResponse, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { PageableTariffsDataDto, PriceTariffs } from 'app/core/types/tariffs.types';
import {
    BehaviorSubject,
    Observable,
    catchError,
    tap
} from 'rxjs';
@Injectable({
    providedIn: 'root',
})

export class TariffsService {
    private _data: BehaviorSubject<PageableTariffsDataDto> = new BehaviorSubject(null);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _formBuilder: FormBuilder
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('TariffService');
    }

    /**
     * Getter for data
     */
    public get data$(): Observable<PageableTariffsDataDto> {
        return this._data.asObservable();
    }

    /**
     * Get data
     */
    public getData(pageNumber: number): Observable<PageableTariffsDataDto> {
        return this._httpClient.post(`${this.apiBase}/priceTariffs/query?page=${pageNumber}&size=9`, ({ isDefault: false })).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: PageableTariffsDataDto) => {
                this._data.next(response);
            })
        );
    }

    public getQueryData(nameSearch: string): Observable<PageableTariffsDataDto> {
        return this._httpClient.post(`${this.apiBase}/priceTariffs/query?page=${0}&size=unpaged`, ({ name: nameSearch, isDefault: false })).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: PageableTariffsDataDto) => {
                this._data.next(response);
            })
        );
    }

    public postNewTariff(newTariff: PriceTariffs): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/priceTariffs`, newTariff);
    }

    public putEdiTariff(editTariff: PriceTariffs, tariffPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/priceTariffs/${tariffPk}`, editTariff);
    }
}
