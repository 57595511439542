import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentService } from '../payment.service';


@Injectable({
    providedIn: 'root',
})
export class PaymentResultCancelResolver implements Resolve<any> {

    /**
     * Constructor
     */
    constructor(
        private _paymentService: PaymentService
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot,
    ): Observable<any> | Observable<never> {
        return this._paymentService.paymentVoid();
    }
}
