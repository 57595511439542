import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/core/http/http-error-handler.service';
import { NonWorkingDayGroupDetails, NonWorkingDayGroups, PostAddressesReq, PostDaysReq, PostGroupReq } from 'app/core/types/settings.types';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class SettingsDaysOffService {
    private handleError: HandleError;
    private _nonWorkingDayGroups: BehaviorSubject<NonWorkingDayGroups> = new BehaviorSubject(null);
    private _nonWorkingDayGroupDetails: BehaviorSubject<NonWorkingDayGroupDetails> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
    ) {
        this.handleError = httpErrorHandler.createHandleError('DaysOffService');
    }

    /**
     * Getter for data
     */
    public get nonWorkingDayGroups$(): Observable<NonWorkingDayGroups> {
        return this._nonWorkingDayGroups.asObservable();
    }
    public get nonWorkingDayGroupDetails$(): Observable<NonWorkingDayGroupDetails> {
        return this._nonWorkingDayGroupDetails.asObservable();
    }
    /**
     * Get data
     */
    public getNonWorkingDayGroups(page: number): Observable<NonWorkingDayGroups> {
        return this._httpClient.get(`${this.apiBase}/nonWorkingDayGroup?page=${page}&size=15&sort=startDateTime,desc`)
            .pipe(
                catchError(this.handleError('getNonWorkingDayGroup', [])),
                tap((response: NonWorkingDayGroups) => {
                    this._nonWorkingDayGroups.next(response);
                })
            );
    }

    public getNonWorkingDayGroupDetails(pk: number): Observable<NonWorkingDayGroupDetails> {
        return this._httpClient.get(`${this.apiBase}/nonWorkingDayGroup/${pk}/details`)
            .pipe(
                catchError(this.handleError('getNonWorkingDayGroup', [])),
                tap((response: NonWorkingDayGroupDetails) => {
                    this._nonWorkingDayGroupDetails.next(response);
                })
            );
    }

    public addNonWorkingDayGroup(req: PostGroupReq): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/nonWorkingDayGroup`, req);
    }

    public addNonWorkingDays(pk: number, req: PostDaysReq): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/nonWorkingDayGroup/${pk}/nonWorkingDay/batch`,
            { nonWorkingDays: [req] });
    }

    public addGroupAddresses(pk: number, req: PostAddressesReq): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/nonWorkingDayGroup/${pk}/address/batch/assign`, req);
    }

    public editNonWorkingDayGroup(pk: number, req: PostGroupReq): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/nonWorkingDayGroup/${pk}`, req);
    }

    public editNonWorkingDay(pk: number, req: PostDaysReq): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/nonWorkingDayGroup/nonWorkingDay/${pk}`, req);
    }

    public unassignGroupAddresses(req: PostAddressesReq): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/nonWorkingDayGroup/address/batch/unassign`, req);
    }
}
