import { Injectable } from '@angular/core';
import {
    Resolve
} from '@angular/router';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { UserService } from 'app/core/user/user.service';
import { Observable, forkJoin, mergeMap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
    constructor(
        private _navigationService: NavigationService,
        private _userService: UserService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(
    ): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._navigationService.get(),
            this._userService.get().pipe(mergeMap(() => this._userService.getUserImage())),
            this._userService.getUserLang(),
            this._userService.getUserGeneralCountryCompanyLocale()
        ]);
    }
}
