/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { BusinessEntityList, BusinessEntityRequestDto, CompanyCounters, SingleBusinessEntity } from 'app/core/types/company.types';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
// import {

// } from 'app/core/types/users.types';

@Injectable({
    providedIn: 'root',
})
export class CompanyService {
    companyForm: FormGroup;
    private _data: BehaviorSubject<BusinessEntityList> = new BehaviorSubject(null);
    private _companyData: BehaviorSubject<SingleBusinessEntity> = new BehaviorSubject(null);
    private _companyCounts: BehaviorSubject<CompanyCounters> = new BehaviorSubject(null);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _formBuilder: FormBuilder
    ) {
        this.handleError = httpErrorHandler.createHandleError('CompanyService');
    }

    get data$(): Observable<BusinessEntityList> {
        return this._data.asObservable();
    }

    get company$(): Observable<SingleBusinessEntity> {
        return this._companyData.asObservable();
    }

    get companyCounts$(): Observable<CompanyCounters> {
        return this._companyCounts.asObservable();
    }

    getCompanyCounters(): Observable<CompanyCounters> {
        return this._httpClient.get(`${this.apiBase}/counters/business`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: CompanyCounters) => {
                this._companyCounts.next(response);
            })
        );
    }

    getQueryData(queryDataCompany: BusinessEntityRequestDto, page: number): Observable<BusinessEntityList> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/businessEntities/query?page=${page}&size=13&sort=businessEntityPk,desc`, queryDataCompany).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: BusinessEntityList) => {
                this._data.next(response);
            })
        );
    }

    getQueryDataForSingleEntity(businessEntityPk: number): Observable<BusinessEntityList | HttpStatusCode | any> {
        const queryDataCompany: any = {
            businessEntityPks: [businessEntityPk],
            businessNames: null,
            deleted: false
        };

        return businessEntityPk ? this._httpClient.post(`${this.apiBase}/companyAdmin/businessEntities/query?page=0&size=30&sort=businessEntityPk,desc`, queryDataCompany).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: BusinessEntityList) => {
                this.setSingleCompanyData(response?.content[0]);
                this._companyData.next(response?.content[0]);
            })
        ) : of({}).pipe(tap(() => this.setSingleCompanyData(null)));
    }

    setSingleCompanyData(companyData: any): void {
        this.companyForm = companyData ? this._formBuilder.group({
            businessEntityPk: [companyData?.businessEntityPk],
            businessName: [companyData?.businessName, Validators.required],
            businessVat: [companyData?.businessVat, Validators.required],
            businessOib: [companyData?.businessOib],
            businessCity: [companyData?.businessCity],
            businessZip: [companyData?.businessZip],
            businessStreet: [companyData?.businessStreet],
            businessHouseNumber: [companyData?.businessHouseNumber],
            billingDate: [companyData?.billingDate, Validators.required],
            billingEmail: [companyData?.billingEmail, Validators.required],
            customerCount: [companyData?.customerCount]
        })
            : this._formBuilder.group({
                businessEntityPk: [null],
                businessName: [null, Validators.required],
                businessVat: [null, Validators.required],
                businessOib: [null],
                businessCity: [null],
                businessZip: [null],
                businessStreet: [null],
                businessHouseNumber: [null],
                billingDate: [null, Validators.required],
                billingEmail: [null, Validators.required]
            });
    }

    putSingleBusinessEntity(businessEntityPk: number, company: any): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/businessEntities/${businessEntityPk}`, company);
    }

    postNewBusinessEntity(company: any): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/businessEntities`, company);
    }

    postBusinessEntityCustomer(req: any): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/businessEntities/customer`, req);
    }
}
