/* eslint-disable @typescript-eslint/no-unused-expressions */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationMessageComponent } from 'app/core/shared/components/notification/notification-message.component';
import { ConfigDataDto, NOTIFICATION_DURATION_MS, NotificationStatus } from 'app/core/types/shared.types';
import { forkJoin } from 'rxjs';
import { ConfigurationService } from '../../../modules/admin/settings/configurations/configurations.service';

@Component({
  selector: 'settings-opera',
  templateUrl: './opera.component.html',
  styleUrls: ['./opera.component.scss']
})
export class OperaComponent implements OnInit, OnDestroy {
  @Input() confData: any;
  @Input() cbId: any;
  operaTenantForm: FormGroup;

  constructor(
    private _configurationService: ConfigurationService,
    private _formBuilder: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private _translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    this._configurationService._tenantData$.subscribe((data) => {
      this.operaTenantForm = this._formBuilder.group({
        chargePointId: [this.cbId],
        configuration: this._formBuilder.group({
          endpointAddress: [data?.configuration?.endpointAddress, Validators.required],
          username: [data?.configuration?.username, Validators.required],
          password: [data?.configuration?.password, Validators.required],
          userId: [data?.configuration?.userId, Validators.required],
          primaryLangId: [data?.configuration?.primaryLangId, Validators.required],
          originEntityId: [data?.configuration?.originEntityId, Validators.required],
          originSystemType: [data?.configuration?.originSystemType, Validators.required],
          destinationEntityId: [data?.configuration?.destinationEntityId, Validators.required],
          destinationSystemType: [data?.configuration?.destinationSystemType, Validators.required],
          longInfo: [data?.configuration?.longInfo, Validators.required],
          shortInfo: [data?.configuration?.shortInfo, Validators.required],
          account: [data?.configuration?.account, Validators.required],
          article: [data?.configuration?.article, Validators.required],
          stationId: [data?.configuration?.stationId],
        }),
        demoConfiguration: this._formBuilder.group({
          endpointAddress: [data?.demoConfiguration?.endpointAddress, Validators.required],
          username: [data?.demoConfiguration?.username, Validators.required],
          password: [data?.demoConfiguration?.password, Validators.required],
          userId: [data?.demoConfiguration?.userId, Validators.required],
          primaryLangId: [data?.demoConfiguration?.primaryLangId, Validators.required],
          originEntityId: [data?.demoConfiguration?.originEntityId, Validators.required],
          originSystemType: [data?.demoConfiguration?.originSystemType, Validators.required],
          destinationEntityId: [data?.demoConfiguration?.destinationEntityId, Validators.required],
          destinationSystemType: [data?.demoConfiguration?.destinationSystemType, Validators.required],
          longInfo: [data?.demoConfiguration?.longInfo, Validators.required],
          shortInfo: [data?.demoConfiguration?.shortInfo, Validators.required],
          account: [data?.demoConfiguration?.account, Validators.required],
          article: [data?.demoConfiguration?.article, Validators.required],
          stationId: [data?.demoConfiguration?.stationId],
        })
      });
      this._changeDetectorRef.detectChanges();
    });
  }

  onCancel(): void {
    this.confData ?
      forkJoin([
        this._configurationService.getConfigurations(),
        this._configurationService.getTenantData()
      ]).subscribe()
      :
      this._configurationService.getTenantDataOnCB(this.cbId).subscribe();
  }

  onSubmit(): void {
    this.confData ?
      this.configOperaPost() :
      this._configurationService.postNewChargePointTenantData(this.operaTenantForm.getRawValue()).subscribe({
        next: () => this.handleAfterFormSubmit(NotificationStatus.success),
        error: () => this.handleFormError(NotificationStatus.error)
      });
  }

  configOperaPost(): void {
    const configDataDto: ConfigDataDto[] = [
      {
        path: 'general.reservation_feature_enabled',
        value: this.confData.reservation_feature
      },
      {
        path: 'reservation.enabled',
        value: this.confData.reservation_enabled
      },
      {
        path: 'ocpp.heartbeat_interval_seconds',
        value: this.confData.heartbeat_interval_seconds
      },
      {
        path: 'reservation.duration_minutes',
        value: this.confData.reservation_duration_minutes
      },
      {
        path: 'discount_groups.enabled',
        value: this.confData.discount_groups_enabled
      },
      {
        path: 'charging.parking_penalties.enabled',
        value: this.confData.charging_parking_penalties_enabled
      },
      {
        path: 'charging.parking_penalties.max_allowed_parking',
        value: this.confData.charging_parking_penalties_max_allowed_parking
      },
      {
        path: 'charging.parking_penalties.price',
        value: this.confData.charging_parking_penalties_price
      }
    ];

    forkJoin([
      this._configurationService.setConfigurationBatch(configDataDto),
      this._configurationService.postNewTenantData(this.operaTenantForm.getRawValue())
    ]).subscribe({
      next: () => this.handleAfterFormSubmit(NotificationStatus.success),
      error: () => this.handleFormError(NotificationStatus.error)
    });
  }

  handleFormError(status: NotificationStatus): void {
    this.showSnackBar(status);
  }

  handleAfterFormSubmit(status: NotificationStatus): void {
    this.showSnackBar(status);
  }

  showSnackBar(status: NotificationStatus): void {
    this._snackBar.openFromComponent(NotificationMessageComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      data: {
        message:
          status === NotificationStatus.error
            ? this._translocoService.translate('Setting.Error')
            : this._translocoService.translate('Setting.Configuration.Success'),
        status: status,
      },
      duration: NOTIFICATION_DURATION_MS,
    });
  }

  ngOnDestroy(): void {
    this._configurationService.resetOperaObservable();
  }
}
