<!-- Table -->
<div *transloco="let t; read: 'Session'" class="grid grid-cols-1 gap-8 w-full">
    <!-- Recent sessions table -->
    <div class="xl:col-span-2 flex flex-col flex-auto bg-card shadow rounded-3xl">
        <div class="flex flex-col justify-between h-240 pt-2 mx-6">
            <div class="overflow-y-auto overflow-x-visible">
                <table class="w-full bg-transparent" mat-table matSort [dataSource]="recentTransactionsDataSource"
                    #recentTransactionsTable>
                    <!-- Date -->
                    <ng-container matColumnDef="date">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('Date') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <div class="flex gap-1 justify-start whitespace-nowrap">
                                <mat-icon class="icon-size-5 text-gray-300" [svgIcon]="'heroicons_outline:calendar'">
                                </mat-icon>
                                <span class="text-gray-500 ">{{ transaction.startDateTime | date: 'dd.MM.yyyy' }}</span>
                            </div>
                            <div class="flex gap-1 justify-start whitespace-nowrap">
                                <mat-icon class="icon-size-5 text-gray-300" [svgIcon]="'heroicons_outline:clock'">
                                </mat-icon>
                                <span class="text-gray-500 ">{{transaction.startDateTime | date: 'H:mm'}} -
                                    {{ transaction.stopDateTime | date: 'H:mm' }}</span>
                            </div>
                        </td>
                    </ng-container>

                    <!-- User -->
                    <ng-container matColumnDef="user">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('Customer') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <span *ngIf="transaction.customerEmail" class="text-gray-500">{{ transaction.customerEmail
                                }}</span>
                            <div *ngIf="!transaction.customerEmail" class="border-b-2 border-slate-200 w-10"></div>
                        </td>
                    </ng-container>

                    <!-- Energy -->
                    <ng-container matColumnDef="energy">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('Info') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <div *ngIf="transaction.kwh" class="flex gap-1 justify-start whitespace-nowrap">
                                <mat-icon class="icon-size-5 text-gray-300"
                                    [svgIcon]="'heroicons_outline:lightning-bolt'">
                                </mat-icon>
                                <span class="text-gray-500 ">{{ transaction.kwh }} kWh</span>
                            </div>
                            <div *ngIf="transaction.duration" class="flex gap-1 justify-start whitespace-nowrap">
                                <mat-icon class="icon-size-5 text-gray-300 " [svgIcon]="'heroicons_outline:clock'">
                                </mat-icon>
                                <span class="text-gray-500 ">{{ transaction.duration }}</span>
                            </div>
                            <div *ngIf="!transaction.kwh && !transaction.duration"
                                class="border-b-2 border-slate-200 w-10"></div>
                        </td>
                    </ng-container>

                    <!-- Type -->
                    <ng-container matColumnDef="type">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('Connector') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <span *ngIf="transaction.connectorId" class="text-gray-500 ">
                                {{ transaction.connectorId }}
                            </span>
                            <div *ngIf="!transaction.connectorId" class="border-b-2 border-slate-200 w-10"></div>
                        </td>
                    </ng-container>

                    <!-- Total cost -->
                    <ng-container matColumnDef="totalCost">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('ChargesSum') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <span *ngIf="transaction.totalCost" class="text-gray-500 ">
                                {{ transaction.totalCost | currency: "€" }}
                            </span>
                            <div *ngIf="!transaction.totalCost" class="border-b-2 border-slate-200 w-10"></div>
                        </td>
                    </ng-container>

                    <!-- Payment -->
                    <ng-container matColumnDef="payment">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <span class="text-lg text-gray-500 font-bold">{{ t('Payment') }}</span>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <span *ngIf="transaction.paid" class="text-gray-500 ">
                                {{ transaction.paid | currency: "€" }}
                            </span>
                            <div *ngIf="!transaction.paid" class="border-b-2 border-slate-200 w-10"></div>
                        </td>
                    </ng-container>

                    <!-- Details -->
                    <ng-container matColumnDef="details">
                        <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            <div class="flex justify-center">
                                <span class="text-lg text-gray-500 text-center font-bold">{{ t('DetailsTxt') }}</span>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let transaction">
                            <div class="flex items-center justify-center">
                                <button mat-icon-button (click)="fillSessionDetailsData(transaction.reservationPk)">
                                    <mat-icon class="icon-size-7 text-gray-400"
                                        [svgIcon]="'heroicons_outline:document-text'"></mat-icon>
                                </button>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="recentTransactionsTableColumns"></tr>
                    <tr class="order-row cursor-default h-16" mat-row
                        *matRowDef="let row; columns: recentTransactionsTableColumns">
                    </tr>
                    <!-- Row shown when there is no matching data after applied filter -->
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell text-center pt-2" colspan="8">
                            <mat-icon [svgIcon]="'heroicons_outline:document'"></mat-icon>
                            <p class="mat-cell text-center">
                                {{ t('NoData') }}
                            </p>
                        </td>
                    </tr>
                </table>
            </div>

            <div>
                <div class="row position-absolute bottom-0 align-self-center text-center pb-4">
                    <div class="flex items-center gap-2 justify-center">
                        <button (click)="changePage(0)">
                            <mat-icon
                                [ngStyle]="pageable.offset === 0 ?  {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                [svgIcon]="'heroicons_outline:chevron-double-left'"></mat-icon>
                        </button>
                        <button (click)="changePage(1)">
                            <mat-icon
                                [ngStyle]="pageable.offset === 0 ?  {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                [svgIcon]="'heroicons_outline:chevron-left'"></mat-icon>
                        </button>
                        <div *ngIf="pageable?.totalElements" class="text-gray-500 w-fit  justify-center">
                            {{pageable?.offset + 1}}-{{pageable?.offset + pageable?.numberOfElements}}
                            {{ t('of') }} {{pageable?.totalElements}}
                        </div>
                        <button (click)="changePage(2)">
                            <mat-icon
                                [ngStyle]="(pageable.offset + pageable.numberOfElements) === pageable?.totalElements ? {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                [svgIcon]="'heroicons_outline:chevron-right'"></mat-icon>
                        </button>
                        <button (click)="changePage(3)">
                            <mat-icon
                                [ngStyle]="(pageable.offset + pageable.numberOfElements) === pageable?.totalElements ? {'color':'rgba(203 213 225)'} : {'color':'rgba(100 116 139)'}"
                                [svgIcon]="'heroicons_outline:chevron-double-right'"></mat-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>