import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalConfig, VISIBILITY_MODE, VisibilityMode } from './global.config.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private _globalEnvFilter: BehaviorSubject<VisibilityMode> = new BehaviorSubject(VISIBILITY_MODE.ENABLED);
  private visibility: VisibilityMode = VISIBILITY_MODE.ENABLED;
  private globalConfig: GlobalConfig;

  constructor() {
    this.setResourceConfig();
  }

  get envFilterString$(): VisibilityMode {
    return this.visibility;
  }
  get envFilter$(): Observable<any> {
    return this._globalEnvFilter.asObservable();
  }

  public setEnvFilter(env: VisibilityMode): void {
    this.visibility = env;
    this._globalEnvFilter.next(env);
  }

  public getResource(key: keyof GlobalConfig): string {
    return this.globalConfig[key];
  }

  private setResourceConfig(): void {
    const host = window.location.origin;
    if (host === 'https://evc-me.hobaca.com' || host === 'https://charge.evcmontenegro.me') {
      this.globalConfig = {
        hubJectProviderID: 'HR-HOB',
        hubJectLat: '42.69214',
        hubJectLong: '19.20011',
        hubJectRadius: '100',
        country: 'Montenegro',
        country2LetterCode: 'ME',
        country3LetterCode: 'MNE',
        mapRestrictionCountry: 'me',
        initLat: '42.36938340',
        initLong: '19.28315310',
        east: '20.352926',
        north: '43.55823',
        south: '41.8297',
        west: '18.433792',
        title: 'EVC Montenegro',
        favicon16: 'assets/favicons/montenegro/favicon-16x16.png',
        favicon32: 'assets/favicons/montenegro/favicon-32x32.png',
        loadingLogo: 'assets/images/logo/montenegro/Logo-bijeli.svg',
        navigationLogo: 'assets/images/logo/montenegro/Logo-bijeli.svg',
        headerLogo: 'assets/images/logo/montenegro/Logo-zeleni.svg',
        loginLogo: 'assets/images/logo/montenegro/Logo-zeleni.svg',
        logOutLogo: 'assets/images/logo/montenegro/Logo-zeleni.svg',
        paymentLoadingLogo: 'assets/images/logo/montenegro/Logo-zeleni_animation.svg',
        paymentHeaderLogo: 'assets/images/logo/montenegro/Logo-zeleni.svg',
        mapPinImg: 'assets/images/logo/montenegro/PinMe.svg',
        mapPinScale: '45',
        qrCodeImg: ''
      };
      return;
    }

    this.globalConfig = {
      hubJectProviderID: 'HR-HOB',
      hubJectLat: '44.28144',
      hubJectLong: '16.44997',
      hubJectRadius: '273.04',
      country: 'Croatia',
      country2LetterCode: 'HR',
      country3LetterCode: 'HRV',
      mapRestrictionCountry: 'hr',
      initLat: '45.815010',
      initLong: '15.981919',
      east: '19.447377',
      north: '46.55502',
      south: '42.338509',
      west: '13.3649',
      title: 'HOBACA EV CHARGE',
      favicon16: 'assets/favicons/hobaca/favicon-16x16.png',
      favicon32: 'assets/favicons/hobaca/favicon-32x32.png',
      loadingLogo: 'assets/images/logo/HOBACA-natamnom-sz.svg',
      navigationLogo: 'assets/images/logo/HOBACA-natamnom-sz.svg',
      headerLogo: 'assets/images/logo/HOBACA-nabijelom-h.svg',
      loginLogo: 'assets/images/logo/HOBACA-nabijelom-sz.svg',
      logOutLogo: 'assets/images/logo/HOBACA-nabijelom-sz.svg',
      paymentLoadingLogo: 'assets/images/logo/HOBACA-bijela-sz_animation.svg',
      paymentHeaderLogo: 'assets/images/logo/HOBACA-nabijelom-h.svg',
      mapPinImg: 'assets/icons/Pin.png',
      mapPinScale: '50',
      qrCodeImg: 'assets/images/logo/HOBACA-nabijelom-v.svg'
    };
  }

  getMobileOperatingSystem(): string {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/windows phone/i.test(userAgent)) {
      return 'Windows';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }

    return 'unknown';
  }

}
