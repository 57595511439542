<mat-dialog-content *transloco="let t; read: read" class="overflow-x-hidden mb-8">

    <div class="flex justify-end">
        <button mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
        </button>
    </div>
    <div class="flex justify-center">
        <div class="flex gap-2 text-gary-500 items-center justify-center">
            <mat-icon color="warn" class="size-6" [svgIcon]="'heroicons_outline:trash'"></mat-icon>
            <mat-card-title class="text-gray-500 font-bold text-2xl">{{ t('Title') }}</mat-card-title>
        </div>
    </div>

    <div class="flex w-full mt-6 text-center text-lg text-gray-500">
        <mat-label>{{ t('DeleteQuestion') }}</mat-label>
    </div>
</mat-dialog-content>
<mat-dialog-actions *transloco="let t; read: read" class="flex flex-row-reverse">
    <div class="flex w-full flex-col justify-center my-4 items-center">
        <button mat-flat-button class="w-2/3 sm:w-1/3 h-10 p-2 text-xl" [color]="'warn'" (click)="submit()">
            {{ t('DeleteButton') }}
        </button>
    </div>
</mat-dialog-actions>