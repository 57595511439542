type ObjectValues<T> = T[keyof T];

export interface GlobalConfig {
    hubJectProviderID: string;
    hubJectLat: string;
    hubJectLong: string;
    hubJectRadius: string;
    country: string;
    country2LetterCode: string;
    country3LetterCode: string;
    mapRestrictionCountry: string;
    initLat: string;
    initLong: string;
    east: string;
    north: string;
    south: string;
    west: string;
    title: string;
    favicon16: string;
    favicon32: string;
    loadingLogo: string;
    navigationLogo: string;
    headerLogo: string;
    loginLogo: string;
    logOutLogo: string;
    paymentLoadingLogo: string;
    paymentHeaderLogo: string;
    mapPinImg: string;
    mapPinScale: string;
    qrCodeImg: string;
}

export const APPLICATION_OWNER = {
    HOBACA: 'HOBACA',
    EVC_ME: 'EVC_ME'
} as const

export type ApplicationOwner = ObjectValues<typeof APPLICATION_OWNER>


export const VISIBILITY_MODE = {
    ENABLED: 'ENABLED',
    DEMO: 'DEMO',
    FREE: 'FREE_MODE'
} as const

export type VisibilityMode = ObjectValues<typeof VISIBILITY_MODE>
