/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
// import {

// } from 'app/core/types/users.types';

@Injectable({
    providedIn: 'root',
})
export class SessionDetailsService {
    private sessionDetailsdata: BehaviorSubject<any> = new BehaviorSubject(null);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
    ) {
        this.handleError = httpErrorHandler.createHandleError('SessionDetailsService');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get sessionDetailsdata$(): Observable<any> {
        return this.sessionDetailsdata.asObservable();
    }


    getSessionDetailsData(pageNumber: number, reservationPk: number): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/ocppMessages/${reservationPk}?page=${pageNumber}&size=7`).pipe(
            catchError(this.handleError('getDetailsData', [])),
            tap((response: any) => {
                this.sessionDetailsdata.next(response);
            })
        );
    }

    getSessionFilterData(pageNumber: number, reservationPk: number, filter: any): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/ocppMessages/${reservationPk}/filter?page=${pageNumber}&size=7`, filter).pipe(
            catchError(this.handleError('getDetailsData', [])),
            tap((response: any) => {
                this.sessionDetailsdata.next(response);
            })
        );
    }

    getMessageTypes(): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/messageType`).pipe(
            catchError(this.handleError('getMessageTypes', []))
        );
    }
}
