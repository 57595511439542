import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { NotificationStatus } from 'app/core/types/shared.types';

@Component({
    templateUrl: './notification-message.component.html',
})
export class NotificationMessageComponent implements OnInit {
    message: string;
    isError: boolean;

    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

    ngOnInit(): void {
        this.message = this.data.message;
        this.isError = this.data.status === NotificationStatus.error;
    }
}
