import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve
} from '@angular/router';
import { Observable, merge } from 'rxjs';
import { ChargerStatusWebSocketService } from './charger-status-webSocket.service';
import { PaymentService } from './payment.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentResolver implements Resolve<any> {
    /**
     * Constructor
     */
    constructor(
        private _paymentService: PaymentService,
        private _chargerWebSocker: ChargerStatusWebSocketService
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Resolver
     *
     * @param route
     * @param state
     */
    resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        const id = String(route.paramMap.get('id'));
        this._paymentService.idCharger = id;

        return merge(
            this._paymentService.getPaymentProvider(id),
            this._paymentService.getSupportData(id)
        );
    }
}
