/* eslint-disable */
import * as moment from 'moment';

export const project = {
    schedule: {
        today: [
            {
                title: 'Punionica Dubrava postala offline',
                time: 'prije 32 minute',
                location: 'Međurička ulica 4',
            },
            {
                title: 'Provjeriti zašto se nitko ne spaja na punionicu B',
                time: '10:30 AM',
            },
            {
                title: 'Punionice 1, 6, 9, 12 pružaju pomoćne usluge HOPS-u',
                time: '11:00 AM',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '12:10 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '05:30 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '07:30 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '09:30 PM',
                location: 'Trnje',
            },
        ],
        tomorrow: [
            {
                title: 'Punionica Dubrava postala Offline',
                time: 'prije 32 minute',
                location: 'neka ulica u Dubravi 10',
            },
            {
                title: 'Provjeriti zašto se nitko ne spaja na punionicu B',
                time: '10:30 AM',
            },
            {
                title: 'Punionice 1, 6, 9, 12 pružaju pomoćne usluge HOPS-u',
                time: '11:00 AM',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '12:10 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '05:30 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '07:30 PM',
                location: 'Trnje',
            },
            {
                title: 'Punionica Trnje ponovno online',
                time: '09:30 PM',
                location: 'Trnje',
            },
        ],
    },
    visitorsVsPageViews: {
        overallScore: 472,
        averageRatio: 45,
        predictedRatio: 55,
        series: [
            {
                name: 'Prihod po danu',
                data: [
                    {
                        x: moment().subtract(65, 'days').toDate(),
                        y: 4769,
                    },
                    {
                        x: moment().subtract(64, 'days').toDate(),
                        y: 4901,
                    },
                    {
                        x: moment().subtract(63, 'days').toDate(),
                        y: 4640,
                    },
                    {
                        x: moment().subtract(62, 'days').toDate(),
                        y: 5128,
                    },
                    {
                        x: moment().subtract(61, 'days').toDate(),
                        y: 5015,
                    },
                    {
                        x: moment().subtract(60, 'days').toDate(),
                        y: 5360,
                    },
                    {
                        x: moment().subtract(59, 'days').toDate(),
                        y: 5608,
                    },
                    {
                        x: moment().subtract(58, 'days').toDate(),
                        y: 5272,
                    },
                    {
                        x: moment().subtract(57, 'days').toDate(),
                        y: 5660,
                    },
                    {
                        x: moment().subtract(56, 'days').toDate(),
                        y: 6026,
                    },
                    {
                        x: moment().subtract(55, 'days').toDate(),
                        y: 5836,
                    },
                    {
                        x: moment().subtract(54, 'days').toDate(),
                        y: 5659,
                    },
                    {
                        x: moment().subtract(53, 'days').toDate(),
                        y: 5575,
                    },
                    {
                        x: moment().subtract(52, 'days').toDate(),
                        y: 5474,
                    },
                    {
                        x: moment().subtract(51, 'days').toDate(),
                        y: 5427,
                    },
                    {
                        x: moment().subtract(50, 'days').toDate(),
                        y: 5865,
                    },
                    {
                        x: moment().subtract(49, 'days').toDate(),
                        y: 5700,
                    },
                    {
                        x: moment().subtract(48, 'days').toDate(),
                        y: 6052,
                    },
                    {
                        x: moment().subtract(47, 'days').toDate(),
                        y: 5760,
                    },
                    {
                        x: moment().subtract(46, 'days').toDate(),
                        y: 5648,
                    },
                    {
                        x: moment().subtract(45, 'days').toDate(),
                        y: 5510,
                    },
                    {
                        x: moment().subtract(44, 'days').toDate(),
                        y: 5435,
                    },
                    {
                        x: moment().subtract(43, 'days').toDate(),
                        y: 5239,
                    },
                    {
                        x: moment().subtract(42, 'days').toDate(),
                        y: 5452,
                    },
                    {
                        x: moment().subtract(41, 'days').toDate(),
                        y: 5416,
                    },
                    {
                        x: moment().subtract(40, 'days').toDate(),
                        y: 5195,
                    },
                    {
                        x: moment().subtract(39, 'days').toDate(),
                        y: 5119,
                    },
                    {
                        x: moment().subtract(38, 'days').toDate(),
                        y: 4635,
                    },
                    {
                        x: moment().subtract(37, 'days').toDate(),
                        y: 4833,
                    },
                    {
                        x: moment().subtract(36, 'days').toDate(),
                        y: 4584,
                    },
                    {
                        x: moment().subtract(35, 'days').toDate(),
                        y: 4822,
                    },
                    {
                        x: moment().subtract(34, 'days').toDate(),
                        y: 4330,
                    },
                    {
                        x: moment().subtract(33, 'days').toDate(),
                        y: 4582,
                    },
                    {
                        x: moment().subtract(32, 'days').toDate(),
                        y: 4348,
                    },
                    {
                        x: moment().subtract(31, 'days').toDate(),
                        y: 4132,
                    },
                    {
                        x: moment().subtract(30, 'days').toDate(),
                        y: 4099,
                    },
                    {
                        x: moment().subtract(29, 'days').toDate(),
                        y: 3849,
                    },
                    {
                        x: moment().subtract(28, 'days').toDate(),
                        y: 4010,
                    },
                    {
                        x: moment().subtract(27, 'days').toDate(),
                        y: 4486,
                    },
                    {
                        x: moment().subtract(26, 'days').toDate(),
                        y: 4403,
                    },
                    {
                        x: moment().subtract(25, 'days').toDate(),
                        y: 4141,
                    },
                    {
                        x: moment().subtract(24, 'days').toDate(),
                        y: 3780,
                    },
                    {
                        x: moment().subtract(23, 'days').toDate(),
                        y: 3929,
                    },
                    {
                        x: moment().subtract(22, 'days').toDate(),
                        y: 3524,
                    },
                    {
                        x: moment().subtract(21, 'days').toDate(),
                        y: 3212,
                    },
                    {
                        x: moment().subtract(20, 'days').toDate(),
                        y: 3568,
                    },
                    {
                        x: moment().subtract(19, 'days').toDate(),
                        y: 3800,
                    },
                    {
                        x: moment().subtract(18, 'days').toDate(),
                        y: 3796,
                    },
                    {
                        x: moment().subtract(17, 'days').toDate(),
                        y: 3870,
                    },
                    {
                        x: moment().subtract(16, 'days').toDate(),
                        y: 3745,
                    },
                    {
                        x: moment().subtract(15, 'days').toDate(),
                        y: 3751,
                    },
                    {
                        x: moment().subtract(14, 'days').toDate(),
                        y: 3310,
                    },
                    {
                        x: moment().subtract(13, 'days').toDate(),
                        y: 3509,
                    },
                    {
                        x: moment().subtract(12, 'days').toDate(),
                        y: 3311,
                    },
                    {
                        x: moment().subtract(11, 'days').toDate(),
                        y: 3187,
                    },
                    {
                        x: moment().subtract(10, 'days').toDate(),
                        y: 2918,
                    },
                    {
                        x: moment().subtract(9, 'days').toDate(),
                        y: 3191,
                    },
                    {
                        x: moment().subtract(8, 'days').toDate(),
                        y: 3437,
                    },
                    {
                        x: moment().subtract(7, 'days').toDate(),
                        y: 3291,
                    },
                    {
                        x: moment().subtract(6, 'days').toDate(),
                        y: 3317,
                    },
                    {
                        x: moment().subtract(5, 'days').toDate(),
                        y: 3716,
                    },
                    {
                        x: moment().subtract(4, 'days').toDate(),
                        y: 3260,
                    },
                    {
                        x: moment().subtract(3, 'days').toDate(),
                        y: 3694,
                    },
                    {
                        x: moment().subtract(2, 'days').toDate(),
                        y: 3598,
                    },
                    {
                        x: moment().subtract(1, 'days').toDate(),
                        y: 3812,
                    },
                ],
            },
            {
                name: 'Prosječno vrijeme punjenja jednog automobila',
                data: [
                    {
                        x: moment().subtract(65, 'days').toDate(),
                        y: 1654,
                    },
                    {
                        x: moment().subtract(64, 'days').toDate(),
                        y: 1900,
                    },
                    {
                        x: moment().subtract(63, 'days').toDate(),
                        y: 1647,
                    },
                    {
                        x: moment().subtract(62, 'days').toDate(),
                        y: 1315,
                    },
                    {
                        x: moment().subtract(61, 'days').toDate(),
                        y: 1807,
                    },
                    {
                        x: moment().subtract(60, 'days').toDate(),
                        y: 1793,
                    },
                    {
                        x: moment().subtract(59, 'days').toDate(),
                        y: 1892,
                    },
                    {
                        x: moment().subtract(58, 'days').toDate(),
                        y: 1846,
                    },
                    {
                        x: moment().subtract(57, 'days').toDate(),
                        y: 1966,
                    },
                    {
                        x: moment().subtract(56, 'days').toDate(),
                        y: 1804,
                    },
                    {
                        x: moment().subtract(55, 'days').toDate(),
                        y: 1778,
                    },
                    {
                        x: moment().subtract(54, 'days').toDate(),
                        y: 2015,
                    },
                    {
                        x: moment().subtract(53, 'days').toDate(),
                        y: 1892,
                    },
                    {
                        x: moment().subtract(52, 'days').toDate(),
                        y: 1708,
                    },
                    {
                        x: moment().subtract(51, 'days').toDate(),
                        y: 1711,
                    },
                    {
                        x: moment().subtract(50, 'days').toDate(),
                        y: 1570,
                    },
                    {
                        x: moment().subtract(49, 'days').toDate(),
                        y: 1507,
                    },
                    {
                        x: moment().subtract(48, 'days').toDate(),
                        y: 1451,
                    },
                    {
                        x: moment().subtract(47, 'days').toDate(),
                        y: 1522,
                    },
                    {
                        x: moment().subtract(46, 'days').toDate(),
                        y: 1801,
                    },
                    {
                        x: moment().subtract(45, 'days').toDate(),
                        y: 1977,
                    },
                    {
                        x: moment().subtract(44, 'days').toDate(),
                        y: 2367,
                    },
                    {
                        x: moment().subtract(43, 'days').toDate(),
                        y: 2798,
                    },
                    {
                        x: moment().subtract(42, 'days').toDate(),
                        y: 3080,
                    },
                    {
                        x: moment().subtract(41, 'days').toDate(),
                        y: 2856,
                    },
                    {
                        x: moment().subtract(40, 'days').toDate(),
                        y: 2745,
                    },
                    {
                        x: moment().subtract(39, 'days').toDate(),
                        y: 2750,
                    },
                    {
                        x: moment().subtract(38, 'days').toDate(),
                        y: 2728,
                    },
                    {
                        x: moment().subtract(37, 'days').toDate(),
                        y: 2436,
                    },
                    {
                        x: moment().subtract(36, 'days').toDate(),
                        y: 2289,
                    },
                    {
                        x: moment().subtract(35, 'days').toDate(),
                        y: 2657,
                    },
                    {
                        x: moment().subtract(34, 'days').toDate(),
                        y: 2804,
                    },
                    {
                        x: moment().subtract(33, 'days').toDate(),
                        y: 2777,
                    },
                    {
                        x: moment().subtract(32, 'days').toDate(),
                        y: 3024,
                    },
                    {
                        x: moment().subtract(31, 'days').toDate(),
                        y: 2657,
                    },
                    {
                        x: moment().subtract(30, 'days').toDate(),
                        y: 2218,
                    },
                    {
                        x: moment().subtract(29, 'days').toDate(),
                        y: 1964,
                    },
                    {
                        x: moment().subtract(28, 'days').toDate(),
                        y: 1674,
                    },
                    {
                        x: moment().subtract(27, 'days').toDate(),
                        y: 1721,
                    },
                    {
                        x: moment().subtract(26, 'days').toDate(),
                        y: 2005,
                    },
                    {
                        x: moment().subtract(25, 'days').toDate(),
                        y: 1613,
                    },
                    {
                        x: moment().subtract(24, 'days').toDate(),
                        y: 1295,
                    },
                    {
                        x: moment().subtract(23, 'days').toDate(),
                        y: 1071,
                    },
                    {
                        x: moment().subtract(22, 'days').toDate(),
                        y: 799,
                    },
                    {
                        x: moment().subtract(21, 'days').toDate(),
                        y: 1133,
                    },
                    {
                        x: moment().subtract(20, 'days').toDate(),
                        y: 1536,
                    },
                    {
                        x: moment().subtract(19, 'days').toDate(),
                        y: 2016,
                    },
                    {
                        x: moment().subtract(18, 'days').toDate(),
                        y: 2256,
                    },
                    {
                        x: moment().subtract(17, 'days').toDate(),
                        y: 1934,
                    },
                    {
                        x: moment().subtract(16, 'days').toDate(),
                        y: 1832,
                    },
                    {
                        x: moment().subtract(15, 'days').toDate(),
                        y: 2075,
                    },
                    {
                        x: moment().subtract(14, 'days').toDate(),
                        y: 1709,
                    },
                    {
                        x: moment().subtract(13, 'days').toDate(),
                        y: 1932,
                    },
                    {
                        x: moment().subtract(12, 'days').toDate(),
                        y: 1831,
                    },
                    {
                        x: moment().subtract(11, 'days').toDate(),
                        y: 1434,
                    },
                    {
                        x: moment().subtract(10, 'days').toDate(),
                        y: 993,
                    },
                    {
                        x: moment().subtract(9, 'days').toDate(),
                        y: 1064,
                    },
                    {
                        x: moment().subtract(8, 'days').toDate(),
                        y: 618,
                    },
                    {
                        x: moment().subtract(7, 'days').toDate(),
                        y: 1032,
                    },
                    {
                        x: moment().subtract(6, 'days').toDate(),
                        y: 1280,
                    },
                    {
                        x: moment().subtract(5, 'days').toDate(),
                        y: 1344,
                    },
                    {
                        x: moment().subtract(4, 'days').toDate(),
                        y: 1835,
                    },
                    {
                        x: moment().subtract(3, 'days').toDate(),
                        y: 2287,
                    },
                    {
                        x: moment().subtract(2, 'days').toDate(),
                        y: 2226,
                    },
                    {
                        x: moment().subtract(1, 'days').toDate(),
                        y: 2692,
                    },
                ],
            },
        ],
    },
};
