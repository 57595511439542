/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from './payment.service';

@Injectable({
    providedIn: 'root',
})

export class PaymentNavigationService {
    constructor(
        private _paymentService: PaymentService,
        private _router: Router
    ) { }

    redirectPaymentProcess(paymentStep: number): void {
        switch (paymentStep) {
            case 0:
                if (!this._paymentService.idCharger) {
                    this._router.navigateByUrl('/invalidCharging');
                    break;
                }
                this._router.navigateByUrl(`/payment/${this._paymentService.idCharger}`);
                break;
            case 1:
                this._router.navigateByUrl('/payment-process-success');
                break;
            case 2:
                this._router.navigateByUrl('/payment-process-error');
                break;
            case 3:
                this._router.navigateByUrl('/chargebox/charging-overview/' + this._paymentService.trackingToken);
                break;
            default:
                this._router.navigateByUrl('/invalidCharging');
                break;
        }
    }

    redirectPayment(token: string): void {
        this._paymentService.getCurrentCB(token).subscribe();

        this._router.navigateByUrl('/chargebox/charging-overview/' + token);
    }
}
