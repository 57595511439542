import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    HandleError,
    HandleErrorPost,
    HttpErrorHandler,
} from 'app/core/http/http-error-handler.service';
import {
    ChargePointDetails,
    ChargePointDetailsResponse,
    ChargeboxPoint,
    ChargeboxPointUpdate,
    ChargerQueryData,
    OcppUrl
} from 'app/core/types/charger.types';
import {
    BehaviorSubject,
    Observable,
    catchError,
    of,
    tap
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChargersService {
    public chargerForm: FormGroup;

    private _data: BehaviorSubject<ChargerQueryData> = new BehaviorSubject(null);
    private _chargePoint: BehaviorSubject<ChargePointDetails> = new BehaviorSubject(null);

    private handleError: HandleError;
    private handleErrorPost: HandleErrorPost;
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _formBuilder: FormBuilder
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('ChargersService');
        this.handleErrorPost =
            httpErrorHandler.createHandleErrorPost('ChargersService');
    }

    public get data$(): Observable<ChargerQueryData> {
        return this._data.asObservable();
    }
    get chargePoint$(): Observable<ChargePointDetails> {
        return this._chargePoint.asObservable();
    }

    public getData(pageNumber: number, sort?: string): Observable<ChargerQueryData> {
        const by = sort ? sort : 'chargeBoxPk,desc';
        return this._httpClient.get(`${this.apiBase}/chargebox?page=${pageNumber}&size=8&sort=${by}`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: ChargerQueryData) => {
                this._data.next(response);
            })
        );
    }

    public getQueryData(chargeboxSearchPattern: any, page: number, size: number, sort?: string): Observable<ChargerQueryData> {
        const by = sort ? sort : 'chargeBoxPk,desc';
        return this._httpClient.post(`${this.apiBase}/chargebox/query?page=${page}&size=${size}&sort=${by}`, chargeboxSearchPattern).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: ChargerQueryData) => {
                this._data.next(response);
            })
        );
    }

    public getChargeboxLink(): Observable<OcppUrl | any> {
        return this._httpClient.get(`${this.apiBase}/home/ocpp`).pipe(
            catchError(this.handleError('getChargeboxLink', [])));
    }

    public getChargepointDetails(chargeboxId: string): Observable<ChargePointDetailsResponse> {
        return chargeboxId
            ? this._httpClient
                .get(`${this.apiBase}/chargebox/details/${chargeboxId}`)
                .pipe(
                    catchError(this.handleError('getChargepoint', [])),
                    tap((response: ChargePointDetailsResponse) => {
                        this._chargePoint.next(response.chargeBox);
                        this.getUpdateChargerForm(response);
                    })
                )
            : of(
                null
            ).pipe(tap(() => this.getCreateChargerForm()));
    }

    public unDeleteCharger(chargeBoxId: string): Observable<HttpStatusCode | any> {
        return this._httpClient
            .put(`${this.apiBase}/chargebox/undelete/${chargeBoxId}`, { chargeBoxId: chargeBoxId })
            .pipe(catchError(this.handleErrorPost('undelteCharger')));
    }

    public createCharger(charger: ChargeboxPoint): Observable<any> {
        return this._httpClient.post(`${this.apiBase}/chargebox/add/single`, charger);
    }

    public updateCharger(charger: ChargeboxPoint): Observable<HttpStatusCode | any> {
        const chargerUpdateDto: ChargeboxPointUpdate = {
            chargeBoxId: charger.chargeBoxId,
            description: charger.description,
            addressPk: charger.addressPk,
            visibility: charger.visibility,
            connectionType: charger.connectionType,
            latitude: charger.latitude,
            longitude: charger.longitude
        };

        return this._httpClient
            .put(`${this.apiBase}/chargebox/update`, chargerUpdateDto)
            .pipe(catchError(this.handleErrorPost('updateCharger')));
    }

    private getCreateChargerForm(): void {
        this.chargerForm = this._formBuilder.group({
            chargeBoxId: ['', [Validators.required]],
            description: [null],
            addressPk: [null, Validators.required],
            latitude: [null, Validators.required],
            longitude: [null, Validators.required]
        });
    }

    private getUpdateChargerForm(charger: ChargePointDetailsResponse): void {
        this.chargerForm = this._formBuilder.group({
            chargeBoxPk: [charger.chargeBox.chargeBoxPk],
            chargeBoxId: [charger.chargeBox.chargeBoxId, [Validators.required]],
            description: [charger.chargeBox.description],
            addressPk: [charger.chargeBox.addressOverview.addressPk],
            latitude: [charger?.chargeBox?.latitude],
            longitude: [charger?.chargeBox?.longitude],
            visibility: [charger.chargeBoxExtras.visibility],
            connectionType: [charger.chargeBoxExtras.connectionType]
        });
    }
}
