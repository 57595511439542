import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { TranslocoModule } from '@ngneat/transloco';
import { QRCodeModule } from 'angularx-qrcode';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { OperaComponent } from './components/opera/opera.component';
import { SessionTableComponent } from './components/session-table/session-table.component';
import { UploadPhotoModalComponent } from './components/upload-photo-modal/upload-photo-modal.component';
import { DragNDropDirective } from './directives/drag-n-drop.directive';
import { PrettyjsonPipe } from './prettyjson/prettyjson.pipe';

@NgModule({
    declarations: [
        UploadPhotoModalComponent,
        OperaComponent,
        SessionTableComponent,
        DeleteModalComponent,
        DragNDropDirective,
        PrettyjsonPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatCardModule,
        ReactiveFormsModule,
        TranslocoModule,
        QRCodeModule,
        //Angular Google Maps
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyA2kJzXC2YHryBl4uMx20C19KRr8tJtpDo',
            libraries: ['places']
        })
    ],
    exports: [
        // modules
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslocoModule,
        QRCodeModule,
        AgmCoreModule,

        // components
        UploadPhotoModalComponent,
        OperaComponent,
        SessionTableComponent,
        DeleteModalComponent,

        //directives
        DragNDropDirective,
        PrettyjsonPipe
    ],
})
export class SharedModule { }
