import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService } from '@ngneat/transloco';
import { NotificationMessageComponent } from 'app/core/shared/components/notification/notification-message.component';
import { NOTIFICATION_DURATION_MS, NotificationStatus, PhotoUploadTypes } from 'app/core/types/shared.types';
import { UploadPhotoModalComponentService } from './upload-photo.service';

@Component({
    selector: 'app-upload-photo-modal',
    templateUrl: './upload-photo-modal.component.html'
})
// copied from settings modal...
export class UploadPhotoModalComponent {
    file?: File;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { params: any; action: number; hasImage: boolean },
        public dialogRef: MatDialogRef<UploadPhotoModalComponent>,
        private _snackBar: MatSnackBar,
        private _transloco: TranslocoService,
        private _uploadPhotoService: UploadPhotoModalComponentService
    ) { }

    /**
     * on file drop handler
     */
    onFileDropped($event): void {
        this.prepareFile($event[0]);
    }

    /**
     * handle file from browsing
     */
    fileBrowseHandler(file): void {
        this.prepareFile(file.target.files[0]);
    }

    /**
     * Convert Files list to normal array list
     *
     * @param files (Files List)
     */
    prepareFile(file: File): void {
        if (this.file) {
            this.file = undefined;
        }
        this.file = file;
    }

    /**
     * format bytes
     *
     * @param bytes (File size in bytes)
     * @param decimals (Decimals point)
     */
    formatBytes(bytes: number): any {
        if (bytes !== undefined) {
            if (bytes === 0) {
                return '0 Bytes';
            }
            const k = 1024;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            const i = Math.floor(Math.log(bytes) / Math.log(k));
            return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
        }
    }

    onSubmit(): void {
        switch (this.data.action) {
            case PhotoUploadTypes.account:
                this._uploadPhotoService.postUserImage(this.file, this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.cb:
                this._uploadPhotoService.postCBImage(this.file, this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.address:
                this._uploadPhotoService.postAddressImage(this.file, this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.instructions:
                this._uploadPhotoService.postInstructionsImage(this.file, this.data.params.vendor, this.data.params.model).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            default:
                break;
        }
    }

    onDelete(): void {
        switch (this.data.action) {
            case PhotoUploadTypes.account:
                this._uploadPhotoService.deleteUserImage(this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.cb:
                this._uploadPhotoService.deleteCBImage(this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.address:
                this._uploadPhotoService.deleteAddressImage(this.data.params).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            case PhotoUploadTypes.instructions:
                this._uploadPhotoService.deleteInstructionsImage(this.data.params.vendor, this.data.params.model).subscribe({
                    next: () => this.handleAfterFormSubmit(NotificationStatus.success),
                    error: () => this.handleFormError(NotificationStatus.error),
                });
                break;
            default:
                break;
        }
    }

    handleAfterFormSubmit(status: NotificationStatus): void {
        this.showSnackBar(status);
        setTimeout(() => this.dialogRef.close({ file: this.file }), NOTIFICATION_DURATION_MS);
    }

    handleFormError(status: NotificationStatus): void {
        this.showSnackBar(status);
    }

    showSnackBar(status: NotificationStatus): void {
        this._snackBar.openFromComponent(NotificationMessageComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: {
                message:
                    status === NotificationStatus.error
                        ? this._transloco.translate('ImageUploadError')
                        : this._transloco.translate('ImageUploadSuccess'),
                status: status,
            },
            duration: NOTIFICATION_DURATION_MS,
        });
    }
}
