import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class UploadPhotoModalComponentService {
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('UploadPhotoService');
    }

    postUserImage(file: any, userPk): Observable<any> {
        const formData = new FormData();
        formData.append('imageFile', file);

        return this._httpClient.post(`${this.apiBase}/users/${userPk}/image`, formData).pipe(
            catchError(this.handleError('postImgData', []))
        );
    }

    postCBImage(file: any, cBId): Observable<any> {
        const formData = new FormData();
        formData.append('imageFile', file);

        return this._httpClient.post(`${this.apiBase}/chargebox/${cBId}/image`, formData).pipe(
            catchError(this.handleError('postImgData', []))
        );
    }

    postAddressImage(file: any, addressId): Observable<any> {
        const formData = new FormData();
        formData.append('imageFile', file);

        return this._httpClient.post(`${this.apiBase}/addresses/${addressId}/image`, formData).pipe(
            catchError(this.handleError('postImgData', []))
        );
    }

    postInstructionsImage(file: any, vendor: string, model: string): Observable<any> {
        const formData = new FormData();
        formData.append('imageFile', file);

        return this._httpClient.post(`${this.apiBase}/chargebox/instructions/image?vendor=${vendor}&model=${model}`, formData).pipe(
            catchError(this.handleError('postImgData', []))
        );
    }

    deleteUserImage(userPk): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/users/${userPk}/image`).pipe(
            catchError(this.handleError('deleteImgData', []))
        );
    }

    deleteCBImage(cBId): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/chargebox/${cBId}/image`).pipe(
            catchError(this.handleError('deleteImgData', []))
        );
    }

    deleteAddressImage(addressId): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/addresses/${addressId}/image`).pipe(
            catchError(this.handleError('deleteImgData', []))
        );
    }

    deleteInstructionsImage(vendor: string, model: string): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/chargebox/instructions/image?vendor=${vendor}&model=${model}`).pipe(
            catchError(this.handleError('deleteImgData', []))
        );
    }
}
