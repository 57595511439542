import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';
import {
    HandleError,
    HttpErrorHandler,
} from '../http/http-error-handler.service';
import { Country } from '../types/shared.types';

@Injectable({
    providedIn: 'root',
})
export class CountryService {
    private _countries: BehaviorSubject<any> = new BehaviorSubject(null);
    private handleError: HandleError;

    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string
    ) {
        this.handleError = httpErrorHandler.createHandleError('CountryService');
    }

    get countries$(): Observable<Country[]> {
        return this._countries.asObservable();
    }

    getCountries(): Observable<Country[]> {
        return this._httpClient.get(`${this.apiBase}/countries`).pipe(
            catchError(this.handleError('getCountries', [])),
            tap((response: any) => {
                this._countries.next(response?.countries);
            })
        );
    }
}
