import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    HandleError,
    HandleErrorPost,
    HttpErrorHandler,
} from 'app/core/http/http-error-handler.service';
import { AddressCityCounters, AddressData, AddressQueryReq, DefaultAddressPriceReq } from 'app/core/types/locations.types';
import {
    BehaviorSubject,
    Observable,
    catchError,
    tap
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    private _data: BehaviorSubject<AddressData> = new BehaviorSubject(null);
    private _mapData: BehaviorSubject<AddressData> = new BehaviorSubject(null);
    private _citys: BehaviorSubject<AddressCityCounters> = new BehaviorSubject(null);
    private _locations: BehaviorSubject<AddressData> = new BehaviorSubject(null);

    private handleError: HandleError;
    private handleErrorPost: HandleErrorPost;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('MapsService');
        this.handleErrorPost =
            httpErrorHandler.createHandleErrorPost('MapsService');
    }

    /**
     * Getter for data
     */
    get data$(): Observable<AddressData> {
        return this._data.asObservable();
    }

    get mapData$(): Observable<AddressData> {
        return this._mapData.asObservable();
    }

    get citys$(): Observable<AddressCityCounters> {
        return this._citys.asObservable();
    }

    get locations$(): Observable<AddressData> {
        return this._locations.asObservable();
    }

    /**
     * Get data
     */
    public getMapData(pageNumber: number, sort?: string): Observable<AddressData> {
        return this._httpClient.get(`${this.apiBase}/addresses?page=${pageNumber}&size=unpaged&sort=addressPk,desc`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: AddressData) => {
                this._mapData.next(response);
            })
        );
    }

    public getData(pageNumber: number, sort?: string): Observable<AddressData> {
        const by = sort ? sort : 'addressPk,desc';
        return this._httpClient.get(`${this.apiBase}/addresses?page=${pageNumber}&size=5&sort=${by}`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: AddressData) => {
                this._data.next(response);
            })
        );
    }

    public getAddressImage(id: number): Observable<Blob | any> {
        const options = {
            responseType: 'blob' as 'json',
        };

        return this._httpClient.get<Blob>(`${this.apiBase}/addresses/${id}/image`, options,).pipe(
            catchError(this.handleError('getData', []))
        );
    }

    public getCityData(): Observable<AddressCityCounters> {
        return this._httpClient.get(`${this.apiBase}/counters/addresses/city`).pipe(
            catchError(this.handleError('getAllData', [])),
            tap((response: AddressCityCounters) => {
                this._citys.next(response);
            })
        );
    }

    public getLocationData(req: AddressQueryReq): Observable<AddressData> {
        return this._httpClient.post(`${this.apiBase}/addresses/query`, req).pipe(
            catchError(this.handleError('getAllData', [])),
            tap((response: AddressData) => {
                this._locations.next(response);
            })
        );
    }

    public getLocationDataForTariff(): Observable<AddressData> {
        return this._httpClient.get(`${this.apiBase}/addresses`).pipe(
            catchError(this.handleError('getAllData', [])),
            tap((response: AddressData) => {
                this._locations.next(response);
            })
        );
    }

    public updateBatchAddressPricing(defaultAddressPrice: DefaultAddressPriceReq): Observable<any> {
        return this._httpClient.patch(`${this.apiBase}/priceTariffs/default/address/batch`, defaultAddressPrice)
            .pipe(catchError(this.handleError('putDefaultTariff')));
    }
}
