import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
    TRANSLOCO_CONFIG,
    TRANSLOCO_LOADER,
    Translation,
    TranslocoModule,
    TranslocoService,
    translocoConfig,
} from '@ngneat/transloco';
import { TranslocoHttpLoader } from 'app/core/transloco/transloco.http-loader';
import { environment } from 'environments/environment';
import { firstValueFrom } from 'rxjs';

@NgModule({
    exports: [TranslocoModule],
    providers: [
        {
            // Provide the default Transloco configuration
            provide: TRANSLOCO_CONFIG,
            useValue: translocoConfig({
                availableLangs: getavailableLangs(),
                defaultLang: 'en_US',
                fallbackLang: 'en_US',
                reRenderOnLangChange: true,
                prodMode: environment.production,
            }),
        },
        {
            // Provide the default Transloco loader
            provide: TRANSLOCO_LOADER,
            useClass: TranslocoHttpLoader,
        },
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            deps: [TranslocoService],
            useFactory:
                (translocoService: TranslocoService): any =>
                    (): Promise<Translation> => {
                        const defaultLang = translocoService.getDefaultLang();
                        translocoService.setActiveLang(defaultLang);
                        return firstValueFrom(translocoService.load(defaultLang));
                    },
            multi: true,
        },
    ],
})
export class TranslocoCoreModule { }

function getavailableLangs() {
    const host = window.location.origin;

    if (host === 'https://evc-me.hobaca.com' || host === 'https://charge.evcmontenegro.me') {
        return [
            {
                id: 'hr_HR',
                label: 'Montenegrin',
            },
            {
                id: 'en_US',
                label: 'English',
            }
        ];
    }

    return [
        {
            id: 'hr_HR',
            label: 'Croatian',
        },
        {
            id: 'en_US',
            label: 'English',
        }
    ];
}
