/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable max-len */
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SessionFilterRequest, SessionListDto } from 'app/core/types/session.types';
import { PaginationTracker } from 'app/core/types/shared.types';
import { SessionDetailsModalComponent } from 'app/modules/admin/session/modal/session-details-modal/session-details-modal.component';
import { SessionDetailsService } from 'app/modules/admin/session/modal/session-details-modal/session-details.service';
import { SessionService } from 'app/modules/admin/session/session.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'session-table',
  templateUrl: './session-table.component.html',
  styleUrls: ['./session-table.component.scss']
})
export class SessionTableComponent implements OnInit, OnDestroy {
  @Input() sessionFilterData: SessionFilterRequest;
  @Input() filtered: boolean;
  recentTransactionsDataSource: MatTableDataSource<SessionListDto> =
    new MatTableDataSource();
  recentTransactionsTableColumns: string[] = [
    'date',
    'user',
    'energy',
    'type',
    'totalCost',
    'payment',
    'details'
  ];
  data: any;
  pageNumber: number = 0;
  pageable: PaginationTracker;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _sessionService: SessionService,
    private _sessionDetailsService: SessionDetailsService,
    private _changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this._sessionService.data$.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        // Store the data
        this.data = data;
        this.pageable = {
          offset: data?.pageable?.offset,
          numberOfElements: data?.numberOfElements,
          totalElements: data?.totalElements,
          totalPages: data?.totalPages
        };
        this._changeDetectorRef.markForCheck();
        // Store the table data
        this.recentTransactionsDataSource.data = data?.content;
      });
  }

  changePage(action: number): void {
    if (action === 0) {
      this.pageNumber = 0;
    }
    else if (this.pageable.totalPages > 1 && action !== 0) {
      if (action === 1 && this.pageNumber !== 0) {
        this.pageNumber = this.pageNumber - 1;
      }
      else if (action === 2 && this.pageNumber < this.pageable.totalPages - 1) {
        this.pageNumber = this.pageNumber + 1;
      }
      else if (action === 3) {
        this.pageNumber = this.pageable.totalPages - 1;
      }
    }

    this.filtered ? this._sessionService.getFilteredData(this.sessionFilterData, this.pageNumber).pipe(takeUntil(this._unsubscribeAll)).subscribe() : this._sessionService.getData(this.pageNumber).pipe(takeUntil(this._unsubscribeAll)).subscribe();

  }

  fillSessionDetailsData(reservationPk: number): void {
    this._sessionDetailsService.getSessionDetailsData(0, reservationPk).pipe(takeUntil(this._unsubscribeAll))
      .subscribe({
        next: () => {
          this.openSessionDetails(reservationPk);
        }
      });
  }
  openSessionDetails(reservationPk: number): void {
    this.dialog.open(SessionDetailsModalComponent, {
      width: '80%',
      height: '80%',
      autoFocus: false,
      data: reservationPk,
      panelClass: 'diagnostics'
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
