/* tslint:disable:max-line-length */
export const chargers = {
    recentTransactions: [
        {
            id: '62b87e0cc9d28083aff7c06e',
            transactionId: '62b87e0cbaa65a023571314e',
            address: '391 Lott Street, Hayes, Puerto Rico, 1317',
            status: true,
            generalStatus: 'Nije integrirana',
            availability: 'Zaključana',
            name: 'Estelle',
        },
        {
            id: '62b87e0c40026a526e533a07',
            transactionId: '62b87e0c56e082d795af6cc5',
            address: '128 Lawrence Street, Brethren, Maine, 8721',
            status: false,
            generalStatus: 'Offline',
            availability: 'Zaključana',
            name: 'Ball',
        },
        {
            id: '62b87e0cfa17b943299ee08c',
            transactionId: '62b87e0c416e58038c87f0ef',
            address: '407 Abbey Court, Frank, West Virginia, 9822',
            status: true,
            generalStatus: 'Offline',
            availability: 'Zaključana',
            name: 'Prince',
        },
        {
            id: '62b87e0c79a1579306e968de',
            transactionId: '62b87e0c303a0594d4d0456e',
            address: '740 Remsen Street, Brogan, Guam, 7258',
            status: false,
            generalStatus: 'Offline',
            availability: 'Javna',
            name: 'Payne',
        },
        {
            id: '62b87e0c06bfc2f4654a203a',
            transactionId: '62b87e0c06211ad7aaab1510',
            address: '297 Emmons Avenue, Chase, Minnesota, 7554',
            status: false,
            generalStatus: 'Offline',
            availability: 'Zaključana',
            name: 'Georgette',
        },
    ],
    chargeSessions: [
        {
            id: '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '528651571NT',
            name: '142.6',
            amount: +1358.75,
            status: 'završeno',
            date: '2019-10-07T22:22:37.274Z',
        },
        {
            id: '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '421436904YT',
            name: '67.7',
            amount: -1042.82,
            status: 'završeno',
            date: '2019-12-18T14:51:24.461Z',
        },
        {
            id: 'ae7c065f-4197-4021-a799-7a221822ad1d',
            transactionId: '685377421YT',
            name: '11.50',
            amount: +1828.16,
            status: 'u tijeku',
            date: '2019-12-25T17:52:14.304Z',
        },
        {
            id: '0c43dd40-74f6-49d5-848a-57a4a45772ab',
            transactionId: '884960091RT',
            name: '212.1',
            amount: +1647.55,
            status: 'završeno',
            date: '2019-11-29T06:32:16.111Z',
        },
        {
            id: 'e5c9f0ed-a64c-4bfe-a113-29f80b4e162c',
            transactionId: '361402213NT',
            name: '55',
            amount: -927.43,
            status: 'završeno',
            date: '2019-11-24T12:13:23.064Z',
        },
    ],
    chargePoints: [
        {
            id: '1b6fd296-bc6a-4d45-bf4f-e45519a58cf5',
            transactionId: '528651571NT',
            name: 'Disconnected',
            amount: 'Public',
            status: 'disconnected',
            date: 'Inactive',
        },
        {
            id: '2dec6074-98bd-4623-9526-6480e4776569',
            transactionId: '421436904YT',
            name: 'Disconnected',
            amount: 'Public',
            status: 'online',
            date: 'Inactive',
        },
    ],
};
