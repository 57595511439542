import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/core/http/http-error-handler.service';
import { BaseDiscountDto, DiscountCustomerSingleData, DiscountCustomers, DiscountCustomersListPageable, DiscountListPageable, DiscountResponse } from 'app/core/types/settings.types';
import { BehaviorSubject, Observable, catchError, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class DiscountService {
    private handleError: HandleError;
    private _discountsData: BehaviorSubject<any> = new BehaviorSubject(null);
    private _discountsDeactivatedData: BehaviorSubject<any> = new BehaviorSubject(null);
    private _discountCustomerData: BehaviorSubject<any> = new BehaviorSubject(null);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('DiscountService');
    }

    public get discountsData$(): Observable<DiscountListPageable> {
        return this._discountsData.asObservable();
    }
    public get discountsDeactivatedData$(): Observable<DiscountListPageable> {
        return this._discountsDeactivatedData.asObservable();
    }
    public get discountCustomerData$(): Observable<DiscountCustomersListPageable> {
        return this._discountCustomerData.asObservable();
    }

    public getDiscountData(page: number): Observable<DiscountListPageable> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/discountGroup/getAll?page=${page}&size=12`, { active: true })
            .pipe(
                catchError(this.handleError('assignCustomersDiscount', [])),
                tap((response: DiscountListPageable) => {
                    this._discountsData.next(response);
                })
            );
    }

    public getDiscountDeactivatedData(page: number): Observable<DiscountListPageable> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/discountGroup/getAll?page=${page}&size=12`, { active: false })
            .pipe(
                catchError(this.handleError('assignCustomersDiscount', [])),
                tap((response: DiscountListPageable) => {
                    this._discountsDeactivatedData.next(response);
                })
            );
    }
    public getDiscountCustomers(discountGroupPk: number): Observable<DiscountCustomersListPageable> {
        return this._httpClient.get(`${this.apiBase}/companyAdmin/discountGroup/${discountGroupPk}/customers/get?page=0&size=unpaged`)
            .pipe(
                tap((response: DiscountCustomersListPageable) => {
                    this._discountCustomerData.next(response);
                })
            );
    }

    public createDiscount(discountData: BaseDiscountDto): Observable<HttpStatusCode | any> {
        return this._httpClient.post(`${this.apiBase}/companyAdmin/discountGroup/create`, discountData);
    }

    public updateDiscount(discountGroupPk: number, discount: BaseDiscountDto): Observable<DiscountResponse | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/discountGroup/${discountGroupPk}/update`, discount);
    }

    public assignDiscount(discountGroupPk: number, customers: DiscountCustomers): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/discountGroup/${discountGroupPk}/assign`, customers);
    }

    public unassignCustomers(customers: DiscountCustomers): Observable<{ customers: DiscountCustomerSingleData[] } | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/discountGroup/customers/unassign`, customers);
    }

    public restoreDiscount(discountGroupPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/discountGroup/${discountGroupPk}/restore`, null);
    }
}
