import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    HandleError,
    HandleErrorPost,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { GetUserDetailsDto, GetUserDetailsNewDto, RolesDto, UserData, UserDto, UserSearchPattern, UsersDto } from 'app/core/types/users.types';
import {
    BehaviorSubject,
    Observable,
    catchError,
    of, tap
} from 'rxjs';
@Injectable({
    providedIn: 'root',
})

export class TeamService {
    public teamForm: FormGroup;

    private _data: BehaviorSubject<UsersDto> = new BehaviorSubject(null);
    private _teamMember: BehaviorSubject<any> = new BehaviorSubject(null);
    private _userRoles: BehaviorSubject<RolesDto> = new BehaviorSubject(null);
    private _deletedTeamMember: BehaviorSubject<UsersDto> = new BehaviorSubject(null);
    private handleError: HandleError;
    private handleErrorPost: HandleErrorPost;
    /**
     * Constructor
     */
    constructor(
        @Inject('API_BASE_URL') private apiBase: string,
        private _httpClient: HttpClient,
        private httpErrorHandler: HttpErrorHandler,
        private _formBuilder: FormBuilder
    ) {
        this.handleError = httpErrorHandler.createHandleError('TeamService');
        this.handleErrorPost = httpErrorHandler.createHandleErrorPost('TeamService');
    }

    public get data$(): Observable<UsersDto> {
        return this._data.asObservable();
    }

    public get teamMember$(): Observable<UserData> {
        return this._teamMember.asObservable();
    }

    public get deletedTeamMember$(): Observable<UsersDto> {
        return this._deletedTeamMember.asObservable();
    }

    public get userRoles$(): Observable<RolesDto> {
        return this._userRoles.asObservable();
    }

    /**
     * Get data
     */
    //TODO on team screen refill data on open screen because if profile changed data team doesnt change because of resolver

    public getQueryData(page: number, deleted: boolean, userSearchPattern?: UserSearchPattern): Observable<UsersDto> {
        let body: UserSearchPattern;
        let url: string;
        if (userSearchPattern) {
            url = '20&sort=userPk,desc';
            body = userSearchPattern;
        }
        else {
            url = deleted ? '7' : '7&sort=userPk,desc';
            body = {
                userPk: null,
                name: '',
                deleted: deleted ? true : false,
                email: ''
            };
        }

        return this._httpClient.post(`${this.apiBase}/companyAdmin/users/query?page=${page}&size=${url}`, body).pipe(
            catchError(this.handleError('getQueryData', [])),
            tap((response: UsersDto) => {
                if (deleted) {
                    this._deletedTeamMember.next(response);
                }
                else { this._data.next(response); }
            })
        );
    }

    public getTeamMemberData(id: number): Observable<UserData | any> {
        return id
            ? this._httpClient.get(`${this.apiBase}/users/${id}`).pipe(
                catchError(this.handleError('getUser', [])),
                tap((response: UserData) => {
                    this._teamMember.next(response);
                    this.getUpdateNewMemberForm(response);
                })
            )
            : of({}).pipe(tap(() => this.getCreateNewMemberForm()));
    }

    public undeleteTeamMember(email: string): Observable<HttpStatusCode | any> {
        return this._httpClient.put(`${this.apiBase}/companyAdmin/users/undelete/${email}`, null).pipe(
            catchError(this.handleError('deleteMember', [])));
    }

    public createNewMember(user: any): Observable<HttpStatusCode | any> {
        if (!user.sex) { user.sex = ''; }

        const newUserData: GetUserDetailsNewDto = {
            firstName: user.firstName,
            lastName: user.lastName,
            birthDay: user.birthDay,
            phone: user.phone,
            note: user.note,
            sex: user.sex,
            email: user.email,
            street: user.street,
            houseNumber: user.houseNumber,
            zipCode: user.zipCode,
            city: user.city,
            country: user.country,
            roles: [{
                rolePk: user.roles.rolePk,
                roleType: user.roles.roleType
            }],
            password: user.password
        };

        return this._httpClient
            .post(`${this.apiBase}/companyAdmin/users`, newUserData)
            .pipe(catchError(this.handleErrorPost('createUser')));
    }

    public updateNewMember(user: any): Observable<HttpStatusCode | any> {
        const oldUserData: GetUserDetailsDto = {
            firstName: user.firstName,
            lastName: user.lastName,
            birthDay: user.birthDay,
            phone: user.phone,
            note: user.note,
            sex: user.sex,
            email: user.email,
            street: user.street,
            houseNumber: user.houseNumber,
            zipCode: user.zipCode,
            city: user.city,
            country: user.country,
            roles: [{
                rolePk: user.roles.rolePk,
                roleType: user.roles.roleType
            }]
        };

        return this._httpClient
            .put(`${this.apiBase}/users/${user.userPk}`, oldUserData)
            .pipe(catchError(this.handleErrorPost('updateUser')));
    }

    public getUserRoles(): Observable<RolesDto> {
        return this._httpClient.get(`${this.apiBase}/roles`).pipe(
            catchError(this.handleError('getUserRoles', [])),
            tap((response: RolesDto) => {
                this._userRoles.next(response);
            })
        );
    }

    private getCreateNewMemberForm(): void {
        this.teamForm = this._formBuilder.group({
            userPk: [0],
            firstName: [null, [Validators.required]],
            lastName: [null, [Validators.required]],
            birthDay: [null],
            phone: [null],
            note: [null],
            sex: [null],
            email: [null, [Validators.required, Validators.email]],
            street: [null],
            houseNumber: [null],
            zipCode: [null],
            city: [null],
            country: [null],
            roles: this._formBuilder.group({
                rolePk: [null, Validators.required],
                name: [null]
            }),
            password: [null, [Validators.required, Validators.minLength(8)]]
        });
    }

    private getUpdateNewMemberForm(teamMember: UserData): void {
        let sex: string = '';
        sex = teamMember.sex === 'm' ? 'MALE' : 'FEMALE';

        this.teamForm = this._formBuilder.group({
            userPk: [teamMember.userPk],
            firstName: [teamMember.firstName, [Validators.required]],
            lastName: [teamMember.lastName, [Validators.required]],
            birthDay: [teamMember.birthDay],
            sex: [sex],
            phone: [teamMember.phone],
            email: [teamMember.email, [Validators.required, Validators.email]],
            note: [teamMember.note],
            street: [teamMember.street],
            houseNumber: [teamMember.houseNumber],
            zipCode: [teamMember.zipCode],
            city: [teamMember.city],
            country: [teamMember.country],
            roles: this._formBuilder.group({
                rolePk: [teamMember.roles[0].rolePk, Validators.required],
                roleType: [teamMember.roles[0].roleType]
            }),
            company: this._formBuilder.group({
                name: [teamMember.company.name],
                vat: [teamMember.company.vat],
                city: [teamMember.company.city],
                zip: [teamMember.company.zip],
                street: [teamMember.company.street],
                houseNumber: [teamMember.company.houseNumber]
            })
        });
    }
}
