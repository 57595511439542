import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    HandleError,
    HandleErrorPost,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { AddressContent, AddressDetails, AddressPostResponse, BusinessPremiseReq, ExpenseGroupDetails, ExpenseGroups, MapLocation } from 'app/core/types/locations.types';
import { NewTariffsReq, PriceTariffContent, PriceTariffsQuery, TariffGroupReq, TariffReq } from 'app/core/types/tariffs.types';
import {
    BehaviorSubject,
    Observable,
    catchError,
    of,
    tap
} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    public mapsForm: FormGroup;
    public settingsTab: string = null;

    private _mapsPoint: BehaviorSubject<AddressContent> = new BehaviorSubject(null);
    private _tariffsData: BehaviorSubject<PriceTariffsQuery> = new BehaviorSubject(null);
    private _expenseDetails: BehaviorSubject<ExpenseGroupDetails> = new BehaviorSubject(null);
    private _expenseGroups: BehaviorSubject<ExpenseGroups> = new BehaviorSubject(null);
    private handleError: HandleError;
    private handleErrorPost: HandleErrorPost;

    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _formBuilder: FormBuilder
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('LocationService');
        this.handleErrorPost =
            httpErrorHandler.createHandleErrorPost('LocationService');
    }

    public get mapsPoint$(): Observable<AddressContent> {
        return this._mapsPoint.asObservable();
    }

    public get tariffsData$(): Observable<PriceTariffsQuery> {
        return this._tariffsData.asObservable();
    }

    public get expenseDetails$(): Observable<ExpenseGroupDetails> {
        return this._expenseDetails.asObservable();
    }

    public get expenseGroups$(): Observable<ExpenseGroups> {
        return this._expenseGroups.asObservable();
    }

    public getLocationData(addressPk: number): Observable<any> {
        return addressPk
            ?
            this._httpClient.get(`${this.apiBase}/addresses/details/${addressPk}`).pipe(
                catchError(this.handleError('getData', [])),
                tap((response: AddressDetails) => {
                    this.fillLocationForm(response);
                    this.getDefaultPriceTariff(response?.address?.addressPk).subscribe();
                    this._mapsPoint.next(response?.address);
                })
            )
            :
            of({}).pipe(tap(() => this.createEmptyLocationForm()));
    }

    public getExpenseGroups(): Observable<ExpenseGroups> {
        return this._httpClient.get(`${this.apiBase}/expenseGroup/companyWide`)
            .pipe(
                catchError(this.handleError('getExpenseGroups', [])),
                tap((response: ExpenseGroups) => {
                    this._expenseGroups.next(response);
                })
            );
    }

    public getDefaultPriceTariff(addressPk: number): Observable<PriceTariffContent> {
        return this._httpClient.get(`${this.apiBase}/priceTariffs/default/address/${addressPk}`).pipe(
            catchError(this.handleError('getDefaultPriceTariff', [])),
            tap((response: PriceTariffContent) => {
                this.patchTariffPrices(response);
            })
        );
    }

    // eslint-disable-next-line max-len
    public updateDefaultPriceTariff(tariff: { acPowerPrice: number; dcUpTo50KwPowerPrice: number; dcAbove50KwPowerPrice: number }, tariffPk: number): Observable<HttpStatusCode | any> {
        return this._httpClient
            .put(`${this.apiBase}/priceTariffs/prices/${tariffPk}`, tariff)
            .pipe(catchError(this.handleErrorPost('updateLocation')));
    }

    public createLocation(mapLocation: MapLocation): Observable<AddressPostResponse | ErrorConstructor> {
        return this._httpClient
            .post<AddressPostResponse>(`${this.apiBase}/addresses`, mapLocation)
            .pipe(catchError(this.handleErrorPost('createLocation')));
    }
    public updateLocation(mapLocation: MapLocation): Observable<HttpStatusCode | any> {
        return this._httpClient
            .put(`${this.apiBase}/addresses/${mapLocation.addressPk}`, mapLocation)
            .pipe(catchError(this.handleErrorPost('updateLocation')));
    }

    public postBusinessPremise(req: BusinessPremiseReq): Observable<HttpStatusCode | any> {
        return this._httpClient
            .post(`${this.apiBase}/businessPremise`, req)
            .pipe(catchError(this.handleErrorPost('postBusinessPremise')));
    }

    public getTariffs(req: { addressPks: number[]; addressCategoryPks?: number[] }): Observable<PriceTariffsQuery> {
        return this._httpClient.post(`${this.apiBase}/priceTariffs/query?page=${0}&size=unpaged`, req).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: PriceTariffsQuery) => {
                this._tariffsData.next(response);
            })
        );
    }


    public getExpenseTariffGroupDetails(pk: number): Observable<ExpenseGroupDetails> {
        return this._httpClient.get(`${this.apiBase}/expenseGroup/${pk}`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: ExpenseGroupDetails) => {
                this._expenseDetails.next(response);
            })
        );
    }

    public postExpenseGroup(req: TariffGroupReq): Observable<HttpStatusCode | any> {
        return this._httpClient
            .post(`${this.apiBase}/expenseGroup`, req)
            .pipe(catchError(this.handleErrorPost('postExpense')));
    }

    public putExpenseGroup(pk: number, req: TariffGroupReq): Observable<HttpStatusCode | any> {
        return this._httpClient
            .put(`${this.apiBase}/expenseGroup/${pk}`, req)
            .pipe(catchError(this.handleErrorPost('putExpenseGroup')));
    }

    public postExpenseTariff(req: NewTariffsReq): Observable<HttpStatusCode | any> {
        return this._httpClient
            .post(`${this.apiBase}/expenseTariff/group`, req)
            .pipe(catchError(this.handleErrorPost('postExpenseTariff')));
    }

    public putExpenseTariff(id: number, req: TariffReq): Observable<HttpStatusCode | any> {
        return this._httpClient
            .put(`${this.apiBase}/expenseTariff/${id}`, req)
            .pipe(catchError(this.handleErrorPost('postExpenseTariff')));
    }


    private patchTariffPrices(tariffData: PriceTariffContent): void {
        this.mapsForm.patchValue({
            priceTariffPk: tariffData.priceTariffPk,
            acPowerPrice: tariffData.acPowerPrice,
            dcUpTo50KwPowerPrice: tariffData.dcUpTo50KwPowerPrice,
            dcAbove50KwPowerPrice: tariffData.dcAbove50KwPowerPrice,
        });
    }

    private createEmptyLocationForm(): void {
        this.mapsForm = this._formBuilder.group({
            id: [null],
            name: ['', [Validators.required]],
            street: ['', Validators.required],
            houseNumber: [''],
            latitude: [''],
            longitude: [''],
            address: ['', [Validators.required]],
            city: [null],
            zipCode: [null],
            country: [null],
            locationType: [''],
            description: [null],
            expenseTariffs: [null],
            additionalExpenses: [null],
            addressCategoryPk: [null],
            priceTariffPk: [null],
            acPowerPrice: ['', [Validators.required]],
            dcUpTo50KwPowerPrice: ['', [Validators.required]],
            dcAbove50KwPowerPrice: ['', [Validators.required]],
            businessPremise: this._formBuilder.group({
                businessPremisePk: null,
                name: null,
                visibility: 'ENABLED',
                sequenceMark: null,
                addressPk: null
            }),
            businessPremiseDemo: this._formBuilder.group({
                businessPremisePk: null,
                name: null,
                visibility: 'DEMO',
                sequenceMark: null,
                addressPk: null
            }),
            daysWorkingHours: [null],
            nonWorkingDayGroup: null
        });
    }

    private fillLocationForm(addressDetails: AddressDetails): void {
        this.mapsForm = this._formBuilder.group({
            id: [addressDetails?.address?.addressPk],
            name: [addressDetails?.address?.name, [Validators.required]],
            street: [addressDetails?.address?.street, Validators.required],
            houseNumber: [addressDetails?.address?.houseNumber],
            latitude: [addressDetails?.address?.latitude],
            longitude: [addressDetails?.address?.longitude],
            address: [''],
            city: [addressDetails?.address?.city],
            zipCode: [addressDetails?.address?.zipCode],
            country: [addressDetails?.address?.country],
            locationType: [addressDetails?.address?.locationType],
            description: [addressDetails?.address?.description],
            expenseTariffs: [addressDetails?.expenseGroups],
            additionalExpenses: [addressDetails?.address?.additionalExpenses],
            addressCategoryPk: [addressDetails?.address?.addressCategory?.addressCategoryPk ? addressDetails?.address?.addressCategory.addressCategoryPk : null],
            priceTariffPk: [null],
            acPowerPrice: ['', [Validators.required]],
            dcUpTo50KwPowerPrice: ['', [Validators.required]],
            dcAbove50KwPowerPrice: ['', [Validators.required]],
            businessPremise: this._formBuilder.group({
                businessPremisePk: addressDetails?.businessPremises?.find(x => x?.visibility === 'ENABLED')?.businessPremisePk,
                name: addressDetails?.businessPremises?.find(x => x.visibility === 'ENABLED')?.name,
                visibility: 'ENABLED',
                sequenceMark: addressDetails?.businessPremises?.find(x => x.visibility === 'ENABLED')?.sequenceMark,
                addressPk: addressDetails?.businessPremises?.find(x => x.visibility === 'ENABLED')?.addressPk
            }),
            businessPremiseDemo: this._formBuilder.group({
                businessPremisePk: addressDetails?.businessPremises?.find(x => x.visibility === 'DEMO')?.businessPremisePk,
                name: addressDetails?.businessPremises?.find(x => x.visibility === 'DEMO')?.name,
                visibility: 'DEMO',
                sequenceMark: addressDetails?.businessPremises?.find(x => x.visibility === 'DEMO')?.sequenceMark,
                addressPk: addressDetails?.businessPremises?.find(x => x.visibility === 'DEMO')?.addressPk
            }),
            daysWorkingHours: [addressDetails?.address?.weekWorkingHours?.daysWorkingHours],
            nonWorkingDayGroup: [addressDetails?.address?.nonWorkingDayGroup]
        });
    }

}
