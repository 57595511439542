import { Route } from '@angular/router';
import { InitialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { PaymentResultCancelResolver } from './modules/payment/payment-result-cancel/payment-result-cancel.resolvers';
import { PaymentResultSuccessResolver } from './modules/payment/payment-result-success/payment-result-success-resolver';
import { PaymentResumeChargingResolver } from './modules/payment/payment-resume-charging.resolvers';
import { PaymentResolver } from './modules/payment/payment.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/home'
    { path: '', pathMatch: 'full', redirectTo: 'home' },

    // Redirect signed in user to the '/home'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.module'
                    ).then(m => m.AuthConfirmationRequiredModule),
            },
            {
                path: 'forgot-password',
                loadChildren: () =>
                    import(
                        'app/modules/auth/forgot-password/forgot-password.module'
                    ).then(m => m.AuthForgotPasswordModule),
            },
            {
                path: 'sign-in',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        m => m.AuthSignInModule
                    ),
            },
            {
                path: 'sign-up',
                loadChildren: () =>
                    import('app/modules/auth/sign-up/sign-up.module').then(
                        m => m.AuthSignUpModule
                    ),
            }
        ],
    },

    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'payment/:id',
                resolve: {
                    data: PaymentResolver,
                },
                loadChildren: () =>
                    import('app/modules/payment/payment.module').then(
                        m => m.PaymentModule
                    ),
            },
            {
                path: 'payment-process-error',
                pathMatch: 'full',
                loadChildren: () =>
                    import(
                        'app/modules/payment/payment-result-error/payment-result-error.module'
                    ).then(m => m.PaymentResultErrorModule),
            },
            {
                path: 'payment-process-cancel',
                pathMatch: 'full',
                resolve: {
                    data: PaymentResultCancelResolver
                },
                children: []
            },
            {
                path: 'payment-process-success',
                pathMatch: 'full',
                resolve: {
                    data: PaymentResultSuccessResolver,
                },
                loadChildren: () =>
                    import(
                        'app/modules/payment/payment-result-success/payment-result-success.module'
                    ).then(m => m.PaymentResultSuccessModule),
            },
            {
                path: 'vendor-error',
                loadChildren: () =>
                    import(
                        'app/modules/payment/vendor-error/error-message.module'
                    ).then(m => m.ErrorMessageModule),
            },
            {
                path: 'loading',
                loadChildren: () =>
                    import(
                        'app/modules/payment/spinner-screen/spinner-screen.module'
                    ).then(m => m.SpinnerScreenModule),
            },
            {
                path: 'invalidCharging',
                loadChildren: () =>
                    import(
                        'app/modules/payment/non-valid-method/non-valid-method.module'
                    ).then(m => m.NonValidMethodModule),
            },
            {
                path: 'chargebox/charging-overview/:GUID',
                loadChildren: () =>
                    import(
                        'app/modules/payment/charging-success/charging-success.module'
                    ).then(m => m.ChargingSuccessModule),
            },
            {
                path: 'chargebox/charging-finished/:GUID',
                loadChildren: () =>
                    import(
                        'app/modules/payment/charging-finished/charging-finished.module'
                    ).then(m => m.ChargingFinishedModule),
            },

            {
                path: 'redirect/:GUID',
                resolve: {
                    data: PaymentResumeChargingResolver
                },
                children: []
            },
            {
                path: 'charging/token-expired',
                loadChildren: () =>
                    import(
                        'app/modules/payment/non-valid-token/non-valid-token.module'
                    ).then(m => m.NonValidTokenModule),
            },
            {
                path: 'help',
                loadChildren: () =>
                    import(
                        'app/modules/payment/help-info/help-info.module'
                    ).then(m => m.HelpInfoModule),
            },
            {
                path: 'customer/verify/:token',
                loadChildren: () =>
                    import('app/modules/auth/verify-account/verify-account.module').then(
                        m => m.VerifyAccountModule
                    ),
            },
            {
                path: 'mobile/payment/verify/:token',
                loadChildren: () =>
                    import('app/modules/auth/mobile-payment-verify/mobile-payment-verify.module').then(
                        m => m.MobilePaymentVerifyModule
                    ),
            },
            {
                path: 'reset-password-form',
                loadChildren: () =>
                    import(
                        'app/modules/auth/reset-password/reset-password.module'
                    ).then(m => m.AuthResetPasswordModule),
            }
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        m => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then(m => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'landing',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        m => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/admin/home/home.module').then(
                        m => m.HomeModule
                    ),
            },
            {
                path: 'notifications',
                loadChildren: () =>
                    import('app/modules/admin/notifications/notifications-activities/notifications-activities.module').then(
                        m => m.NotificationsActivityModule
                    ),
            },
            {
                path: 'analytics',
                loadChildren: () =>
                    import('app/modules/admin/analytics/analytics.module').then(
                        m => m.AnalyticsModule
                    ),
            },
            {
                path: 'finance',
                loadChildren: () =>
                    import('app/modules/admin/finance/finance.module').then(
                        m => m.FinanceModule
                    ),
            },
            {
                path: 'chargers',
                loadChildren: () =>
                    import('app/modules/admin/chargers/chargers.module').then(
                        m => m.ChargersModule
                    ),
            },
            {
                path: 'roaming',
                loadChildren: () =>
                    import('app/modules/admin/roaming/roaming.module').then(
                        m => m.RoamingModule
                    ),
            },
            {
                path: 'hub/overview/:id',
                loadChildren: () =>
                    import('app/modules/admin/roaming/roaming-overview/roaming-overview.module').then(
                        m => m.RoamingOverivewModule
                    ),
            },
            {
                path: 'customers',
                loadChildren: () =>
                    import('app/modules/admin/customers/customers.module').then(
                        m => m.CustomersModule
                    ),
            },
            {
                path: 'business',
                loadChildren: () =>
                    import('app/modules/admin/company/company.module').then(
                        m => m.CompanyModule
                    ),
            },
            {
                path: 'business/:id',
                loadChildren: () =>
                    import('app/modules/admin/company/company-overview/company-overview.module').then(
                        m => m.CompanyOverviewModule
                    ),
            },
            {
                path: 'user/:id',
                loadChildren: () =>
                    import('app/modules/admin/customers/user-overview/customer-overview.module').then(
                        m => m.UserOverviewModule
                    ),
            },
            {
                path: 'reports',
                loadChildren: () =>
                    import('app/modules/admin/reports/reports.module').then(
                        m => m.ReportsModule
                    ),
            },
            {
                path: 'settings',
                loadChildren: () =>
                    import('app/modules/admin/settings/setting.module').then(
                        m => m.SettingsModule
                    ),
            },
            {
                path: 'locations',
                loadChildren: () =>
                    import('app/modules/admin/locations/locations.module').then(
                        m => m.LocationsModule
                    ),
            },
            {
                path: 'locations/location',
                loadChildren: () =>
                    import('app/modules/admin/locations/location/location.module').then(
                        m => m.LocationModule
                    ),
            },
            {
                path: 'summary/:id/:chargeBoxId',
                loadChildren: () =>
                    import(
                        'app/modules/admin/chargers/charger-summary/charger-summary.module'
                    ).then(m => m.ChargerSummaryModule),
            },
            {
                path: 'session',
                loadChildren: () =>
                    import('app/modules/admin/session/session.module').then(
                        m => m.SessionModule
                    ),
            },
            {
                path: 'price-calendar',
                loadChildren: () =>
                    import('app/modules/admin/price-calendar/price-calendar.module').then(
                        m => m.PriceCalendarModule
                    ),
            },

            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/admin/error/error-404/error-404.module').then(m => m.Error404Module) },
            { path: '**', redirectTo: '404-not-found' }
        ],
    },
];
