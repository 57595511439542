/* eslint-disable prefer-arrow/prefer-arrow-functions */
export function toISOLocal(d: Date): string {
    const pad = (n: number, digits: number = 2): string => n.toString().padStart(digits, '0');
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T${pad(d.getHours())}:${pad(d.getMinutes())}:${pad(d.getSeconds())}.${pad(d.getMilliseconds(), 3)}Z`;
}

export function toISOLocalRoundDay(d: Date): string {
    const pad = (n: number, digits: number = 2): string => n.toString().padStart(digits, '0');
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T00:00:00.000Z`;
};

export function toISOLocalRoundEndDay(d: Date): string {
    const pad = (n: number, digits: number = 2): string => n.toString().padStart(digits, '0');
    return `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}T23:59:59.000Z`;
};

export const NOTIFICATION_DURATION_MS: number = 1000;

export interface Pageable {
    totalPages: number;
    totalElements: number;
    pageable: {
        paged: boolean;
        unpaged: boolean;
        pageNumber: number;
        pageSize: number;
        offset: number;
        sort: {
            unsorted: boolean;
            sorted: boolean;
            empty: boolean;
        };
    };
    numberOfElements: number;
    first: boolean;
    last: boolean;
    size: number;
    // eslint-disable-next-line id-blacklist
    number: number;
    sort: {
        unsorted: boolean;
        sorted: boolean;
        empty: boolean;
    };
    empty: boolean;
}

export interface PaginationTracker {
    offset: number;
    numberOfElements: number;
    totalElements: number;
    totalPages: number;
}

export interface ChartDateDataSeries {
    x: string;
    y: number;
}

export interface DropdownListItem {
    id: number | string;
    name: string;
    latitude?: number;
    longitude?: number;
    hasVIP?: boolean;
}

export interface PageableTariffsDataDto extends Pageable {
    content: PriceTariffs[];
}
export interface PriceTariffs {
    priceTariffPk?: number;
    name: string;
    acPowerPrice: number;
    dcUpTo50KwPowerPrice: number;
    dcAbove50KwPowerPrice: number;
    start: string;
    end: string;
    priceTariffMetadata: {
        activeHoursFrom: string;
        activeHoursTo: string;
        repeatHourInDay: Array<number>;
        repeatDayInWeek: Array<number>;
        repeatDayInMonth: Array<number>;
        repeatWeekInMonth: Array<number>;
        repeatWeekInYear: Array<number>;
        repeatMonthInYear: Array<number>;
        deactivatedDate: Array<string>;
    };
    addressPks: Array<number>;
    addressCategoryPks: Array<number>;
}

export interface StatsResponseDto {
    type: string;
    data: [{
        timestamp: Date;
        value: number;
    }];
    sum: number;
}

export const DATE_STRING_FORMAT = 'YYYY-MM-DD';

export const DATE_FORMAT = {
    parse: {
        dateInput: DATE_STRING_FORMAT,
    },
    display: {
        dateInput: DATE_STRING_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export enum DeleteTypes {
    charger,
    location,
    businessPremise,
    customer,
    tariff,
    category,
    discount,
    team,
    workHours,
    dayOffGroup,
    dayOffDay,
    expenseGroup,
    expenseGroupSettings,
    expenseTariff,
    company,
    companyCustomer
}

export enum PhotoUploadTypes {
    account,
    cb,
    address,
    instructions
}

export interface TaskId {
    taskId: number;
}

export interface TaskIds {
    taskIds: number[];
}

export interface ConfigurationsTasks {
    configuration: string;
    accepted: string;
    pending: boolean;
    taskId: number;
}

export interface ConfigurationTask {
    configuration: string;
    value: string;
    pending: boolean;
    accepted: string;
    taskId: number;
    details: any;
    show: boolean;
}

export interface TaskDetails {
    taskId: number;
    task: {
        ocppVersion: string;
        operationName: string;
        origin: string;
        caller: string;
        params: any;
        resultMap: {
            additionalProp1: {
                response: string;
                handlingErrorMessage: string;
                sendingError: boolean;
                details: any;
            };
            additionalProp2: {
                response: string;
                handlingErrorMessage: string;
                sendingError: boolean;
                details: any;
            };
            additionalProp3: {
                response: string;
                handlingErrorMessage: string;
                sendingError: boolean;
                details: any;
            };
        };
        resultSize: number;
        startTimestamp: string;
        endTimestamp: string;
        responseMessage: string;
        errorCount: {
            opaque: number;
            acquire: number;
            andIncrement: number;
            andDecrement: number;
            plain: number;
        };
        responseCount: {
            opaque: number;
            acquire: number;
            andIncrement: number;
            andDecrement: number;
            plain: number;
        };
    };
}
export enum NotificationStatus {
    success = 'success',
    error = 'error',
}

export interface ConfigDataDto {
    value: string;
    path: string;
}

export interface Country {
    name?: string;
    alpha2?: string;
    alpha3?: string;
    numeric?: number;
    assignment?: string;
}

export enum Sex {
    female = 'FEMALE',
    male = 'MALE'
}

export interface StatsRequestDto {
    name: string;
    startTimestamp: string;
    endTimestamp: string;
    interval: string;
    timeResolution: string;
    missingValueType: string;
    params: {
        chargeBoxIds: string;
    };
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const HoursInDay: Array<{ display: string; value: string }> = [
    {
        display: '--:--',
        value: ''
    },
    {
        display: '00:00',
        value: '00:00:00'
    },
    {
        display: '00:30',
        value: '00:30:00'
    },
    {
        display: '01:00',
        value: '01:00:00'
    },
    {
        display: '01:30',
        value: '01:30:00'
    },
    {
        display: '02:00',
        value: '02:00:00'
    },
    {
        display: '02:30',
        value: '02:30:00'
    },
    {
        display: '03:00',
        value: '03:00:00'
    },
    {
        display: '03:30',
        value: '03:30:00'
    },
    {
        display: '04:00',
        value: '04:00:00'
    },
    {
        display: '04:30',
        value: '04:30:00'
    },
    {
        display: '05:00',
        value: '05:00:00'
    },
    {
        display: '05:30',
        value: '05:30:00'
    },
    {
        display: '06:00',
        value: '06:00:00'
    },
    {
        display: '06:30',
        value: '06:30:00'
    },
    {
        display: '07:00',
        value: '07:00:00'
    },
    {
        display: '07:30',
        value: '07:30:00'
    },
    {
        display: '08:00',
        value: '08:00:00'
    },
    {
        display: '08:30',
        value: '08:30:00'
    },
    {
        display: '09:00',
        value: '09:00:00'
    },
    {
        display: '09:30',
        value: '09:30:00'
    },
    {
        display: '10:00',
        value: '10:00:00'
    },
    {
        display: '10:30',
        value: '10:30:00'
    },
    {
        display: '11:00',
        value: '11:00:00'
    },
    {
        display: '11:30',
        value: '11:30:00'
    },
    {
        display: '12:00',
        value: '12:00:00'
    },
    {
        display: '12:30',
        value: '12:30:00'
    },
    {
        display: '13:00',
        value: '13:00:00'
    },
    {
        display: '13:30',
        value: '13:30:00'
    },
    {
        display: '14:00',
        value: '14:00:00'
    },
    {
        display: '14:30',
        value: '14:30:00'
    },
    {
        display: '15:00',
        value: '15:00:00'
    },
    {
        display: '15:30',
        value: '15:30:00'
    },
    {
        display: '16:00',
        value: '16:00:00'
    },
    {
        display: '16:30',
        value: '16:30:00'
    },
    {
        display: '17:00',
        value: '17:00:00'
    },
    {
        display: '17:30',
        value: '17:30:00'
    },
    {
        display: '18:00',
        value: '18:00:00'
    },
    {
        display: '18:30',
        value: '18:30:00'
    },
    {
        display: '19:00',
        value: '19:00:00'
    },
    {
        display: '19:30',
        value: '19:30:00'
    },
    {
        display: '20:00',
        value: '20:00:00'
    },
    {
        display: '20:30',
        value: '20:30:00'
    },
    {
        display: '21:00',
        value: '21:00:00'
    },
    {
        display: '21:30',
        value: '21:30:00'
    },
    {
        display: '22:00',
        value: '22:00:00'
    },
    {
        display: '22:30',
        value: '22:30:00'
    },
    {
        display: '23:00',
        value: '23:00:00'
    },
    {
        display: '23:30',
        value: '23:30:00'
    },
];
