/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/core/http/http-error-handler.service';
import { GetOperaTenant, GetOperaTenantConf, OperaTenantCB } from 'app/core/types/settings.types';
import { ConfigDataDto } from 'app/core/types/shared.types';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class ConfigurationService {
    private handleError: HandleError;
    private _configurations: BehaviorSubject<ConfigDataDto[]> = new BehaviorSubject(null);
    private _tenantData: BehaviorSubject<GetOperaTenant> = new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('ConfigurationService');
    }

    public get configuration$(): Observable<ConfigDataDto[]> {
        return this._configurations.asObservable();
    }

    public get _tenantData$(): Observable<GetOperaTenant> {
        return this._tenantData.asObservable();
    }

    public resetOperaObservable(): void {
        this._tenantData.next(null);
    }

    public getConfigurations(): Observable<ConfigDataDto[]> {
        return this._httpClient.post(`${this.apiBase}/configuration/batch`, [
            { 'path': 'general.reservation_feature_enabled' },
            { 'path': 'ocpp.heartbeat_interval_seconds' },
            { 'path': 'reservation.enabled' },
            { 'path': 'reservation.duration_minutes' },
            { 'path': 'payment.provider.supported.enabled' },
            { 'path': 'discount_groups.enabled' },
            { 'path': 'charging.parking_penalties.enabled' },
            { 'path': 'charging.parking_penalties.max_allowed_parking' },
            { 'path': 'charging.parking_penalties.price' }
        ]).pipe(
            catchError(this.handleError('getAllData', [])),
            tap((response: ConfigDataDto[]) => {
                this._configurations.next(response);
            })
        );
    }

    public setConfigurationBatch(conf: ConfigDataDto[]): Observable<HttpStatusCode | any> {
        return this._httpClient.patch(`${this.apiBase}/configuration/batch`, conf);
    }

    public getTenantData(): Observable<GetOperaTenant> {
        return this._httpClient.get(`${this.apiBase}/opera/tenant`).pipe(
            catchError(this.handleError('getData', [])),
            tap((response: GetOperaTenant) => {
                this._tenantData.next(response);
            })
        );
    }
    public postNewTenantData(tenant: GetOperaTenantConf): Observable<GetOperaTenant | any> {
        return this._httpClient.post(`${this.apiBase}/opera/tenant`, tenant).pipe(
            catchError(this.handleError('postNewTenantData'))
        );
    }

    public getTenantDataOnCB(chargePointId: string): Observable<GetOperaTenant> {
        return this._httpClient.get(`${this.apiBase}/opera/tenant/chargePoint/${chargePointId}`).pipe(
            catchError(() => of(null)),
            tap((response: GetOperaTenant) => {
                this._tenantData.next(response);
            })
        );
    }
    public postNewChargePointTenantData(tenant: OperaTenantCB): Observable<GetOperaTenant | any> {
        return this._httpClient.post(`${this.apiBase}/opera/tenant/chargePoint`, tenant).pipe(
            catchError(this.handleError('postNewTenantData'))
        );
    }
}
