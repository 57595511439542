/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
    HandleError,
    HttpErrorHandler
} from 'app/core/http/http-error-handler.service';
import { BehaviorSubject, Observable, catchError, of, tap } from 'rxjs';
// import {

// } from 'app/core/types/users.types';
import { FormGroup } from '@angular/forms';
import { DownloadSession, SessionFilterDto, SessionFilterRequest, SessionListDto } from 'app/core/types/session.types';
import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class SessionService {
    userForm: FormGroup;

    status;
    errorMessage;

    private _data: BehaviorSubject<any> = new BehaviorSubject(null);
    private _userData: BehaviorSubject<any> = new BehaviorSubject(null);
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
    ) {
        this.handleError = httpErrorHandler.createHandleError('SessionService');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for data
     */
    get data$(): Observable<any> {
        return this._data.asObservable();
    }

    /**
     * Getter for data
     */
    get user$(): Observable<any[]> {
        return this._userData.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get data
     */
    getData(pageNumber: number): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/charging/sessions?page=${pageNumber}&size=13&sort=startDateTime,desc`).pipe(
            catchError(error => of([this.handleError('getData', []), this._data.next(null)])),
            tap((response: any) => {
                response?.content?.forEach((element) => {
                    if (element.stopDateTime) {
                        const durationTime = new Date(element.stopDateTime).valueOf() - new Date(element.startDateTime).valueOf();

                        const formatTime = moment.duration(durationTime, 'milliseconds');
                        element.duration = (formatTime.hours().toString() + 'h ' + formatTime.minutes().toString() + 'm ' + formatTime.seconds().toString() + 's');
                    }
                    else {
                        element.duration = '';
                    }
                });
                this._data.next(response);
            })
        );
    }

    getSessionExport(sessionReq: DownloadSession): Observable<any> {
        const options = {
            'headers': new HttpHeaders({
                'Accept': '*/*'
            }),
            'responseType': 'blob' as 'json'
        };

        return this._httpClient.post<string>(`${this.apiBase}/charging/sessions/csv`, sessionReq, options).pipe(
            catchError(this.handleError('getQueryData', []))
        );
    }

    getFilteredData(sessionReq: SessionFilterRequest, pageNumber: number): Observable<SessionListDto> {
        return this._httpClient.post(`${this.apiBase}/charging/sessions/query?page=${pageNumber}&size=13&sort=startDateTime,desc`, sessionReq).pipe(
            catchError(this.handleError('getQueryData', [])),
            tap((response: any) => {
                response.content.forEach((element) => {
                    if (element?.startDateTime && element?.stopDateTime) {
                        const durationTime = new Date(element.stopDateTime).valueOf() - new Date(element.startDateTime).valueOf();

                        const formatTime = moment.duration(durationTime, 'milliseconds');
                        element.duration = (formatTime.hours().toString() + 'h ' + formatTime.minutes().toString() + 'm ' + formatTime.seconds().toString() + 's');
                    }
                });

                this._data.next(response);
            })
        );
    }

    getCustomerFilterData(customerEmail: string): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/charging/sessions/filter/customer?customerEmail=${customerEmail}`).pipe(
            catchError(this.handleError('getCustomerFilterData', [])),
            tap((response: SessionFilterDto) => {
            })
        );
    }

    getLocationFilterData(addressName: string): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/charging/sessions/filter/address?addressName=${addressName}`).pipe(
            catchError(this.handleError('getCustomerFilterData', [])),
            tap((response: SessionFilterDto) => {
            })
        );
    }
}
