<div *transloco="let t; read: 'Upload'"
    class="h-full flex flex-col w-full border-2 border-dashed border-gray-300 rounded-2xl" appDragNDrop
    (fileDropped)="onFileDropped($event)">
    <input type="file" accept=".jpg,.png,.jpeg,.gif,.svg" class="hidden" #fileDropRef id="fileDropRef"
        (change)="fileBrowseHandler($event)" />

    <div class="pl-40 pr-40 pt-4 flex justify-center items-center">
        <mat-icon class="object-cover icon-size-20" [svgIcon]="'heroicons_solid:cloud-arrow-up'"></mat-icon>
    </div>

    <div class="flex flex-col gap-3 p-3 pb-7">
        <span class="w-full text-center text-3xl">
            {{ t('D&D') }}
        </span>
        <span class="w-full text-center text-xl text-secondary">
            {{ t('Or') }}
        </span>

        <div class="flex justify-center">
            <button class="w-50 items-center justify-center bg-slate-200" mat-flat-button>
                <label class="flex flex-col items-center h-full w-48 justify-center cursor-pointer" for="fileDropRef">
                    {{ t('BrowseF') }}
                </label>
            </button>
        </div>

        <div *ngIf="file" class="flex justify-between">
            <h4 class="name">
                {{ file?.name }}
            </h4>
            <p class="size">
                {{ formatBytes(file?.size) }}
            </p>
        </div>
    </div>
</div>

<div *transloco="let t; read: 'Upload'">
    <div *ngIf="data.hasImage" class="flex mt-7 w-full">
        <button (click)="onDelete()" class="w-1/2 mr-3" mat-flat-button [color]="'warn'">{{ t('Delete') }}</button>
        <button (click)="onSubmit()" class="w-1/2 ml-3" mat-flat-button [color]="'primary'">{{ t('Save') }}</button>
    </div>
    <div *ngIf="!data.hasImage" class="flex pt-7 w-full">
        <button (click)="onSubmit()" class="w-full" mat-flat-button [color]="'primary'">{{ t('Save') }}</button>
    </div>
</div>
