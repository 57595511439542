/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { HttpClient, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import {
    HandleError,
    HandleErrorPost,
    HttpErrorHandler,
} from 'app/core/http/http-error-handler.service';
import {
    InitialPaymentDto,
    InitialPaymentResponseDto,
    KWhPrice,
    PaymentDiscountGroupResposne,
    PaymentProviderInfoResposne,
    PaymentProviders,
    SupportUrls,
    VendorErrorResponseDto
} from 'app/core/types/payment.types';
import { UserService } from 'app/core/user/user.service';
import { BehaviorSubject, Observable, catchError, firstValueFrom, of, tap } from 'rxjs';


@Injectable({
    providedIn: 'root',
})

export class PaymentService {
    private handleErrorPost: HandleErrorPost;
    private handleError: HandleError;
    private _initPaymentData: BehaviorSubject<InitialPaymentResponseDto> = new BehaviorSubject(null);
    private _paymentProvider: BehaviorSubject<PaymentProviders> = new BehaviorSubject(null);
    private _supportUrls: BehaviorSubject<SupportUrls> = new BehaviorSubject(null);
    private _vendorError: BehaviorSubject<VendorErrorResponseDto> = new BehaviorSubject(null);

    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        private router: Router,
        @Inject('API_BASE_URL') private apiBase: string,
        private _userService: UserService,
        private _translocoService: TranslocoService
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('PaymentService');
        this.handleErrorPost =
            httpErrorHandler.createHandleErrorPost('PaymentService');
    }

    set trackingToken(trackingToken: string) {
        localStorage.setItem('trackingToken', trackingToken);
    }

    get trackingToken() {
        return localStorage.getItem('trackingToken');
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    set idCharger(reservationPk: string) {
        localStorage.setItem('idCharger', reservationPk.toString());
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get idCharger() {
        return localStorage.getItem('idCharger') ?? '';
    }

    set chargerType(chargerType: string) {
        localStorage.setItem('chargerType', chargerType);
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get chargerType() {
        return localStorage.getItem('chargerType') ?? '';
    }

    set paymentStep(step: number) {
        localStorage.setItem('paymentStep', step.toString());
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    get paymentStep() {
        return Number(localStorage.getItem('paymentStep')) ?? 0;
    }
    set selectedLang(lang: string) {
        localStorage.setItem('selectedLang', lang);
    }

    get selectedLang() {
        return localStorage.getItem('selectedLang') ?? 'en_US';
    }

    get paymentProvider$(): Observable<PaymentProviders> {
        return this._paymentProvider.asObservable();
    }

    get supportUrls$(): Observable<SupportUrls> {
        return this._supportUrls.asObservable();
    }
    get vednorError$(): Observable<VendorErrorResponseDto> {
        return this._vendorError.asObservable();
    }

    deleteLocalTempData(): void {
        if (this.trackingToken) {
            this.paymentVoidOnInit();
        }

        localStorage.removeItem('trackingToken');
        localStorage.removeItem('paymentStep');
        localStorage.removeItem('selectedLang');
    }

    /**
     * Get data
     */

    getPaymentProvider(chargeBoxId: string): Observable<any> {
        const options = {
            'headers': new HttpHeaders().set('chargeBoxId', chargeBoxId).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        const providerData: PaymentProviders = {
            supportedProviders: [],
            supportedMethods: [],
            minKwh: 0,
            minPrice: 0,
            discountGroupsEnabled: false,
            parkingPenaltiesEnabled: false,
            maxAllowedStayingWithoutChargingMinutes: 0,
            stayingWithoutChargingPrice: 0
        };

        return this._httpClient.get(`${this.apiBase}/public/payments/info`, options).pipe(
            catchError(() => of(null)),
            tap((response: PaymentProviderInfoResposne) => {
                if (response?.paymentProviders) {
                    response?.paymentProviders?.forEach((paymentProviderInitData) => {
                        providerData.supportedProviders.push(paymentProviderInitData?.paymentProvider);
                        providerData.supportedMethods.push(...paymentProviderInitData?.supportedMethods);
                    });
                    providerData.minKwh = response.minKwh;
                    providerData.minPrice = response.minPrice;
                    providerData.discountGroupsEnabled = response.discountGroupsEnabled;

                    providerData.parkingPenaltiesEnabled = response.parkingPenaltiesEnabled;
                    providerData.maxAllowedStayingWithoutChargingMinutes = response.parkingPenaltiesEnabled ? response.maxAllowedStayingWithoutChargingMinutes : 0;
                    providerData.stayingWithoutChargingPrice = response.parkingPenaltiesEnabled ? response.stayingWithoutChargingPrice : 0;

                    this._paymentProvider.next(providerData);
                }
            })
        );
    }
    getPricePerKwh(connectorId: number): Observable<KWhPrice | any> {
        const options = {
            'headers': new HttpHeaders().set('chargeBoxId', this.idCharger)
        };

        return this._httpClient.get(`${this.apiBase}/public/priceTariffs/active?connectorId=${connectorId}`, options).pipe(
            catchError(this.handleError('getChargerPorts', []))
        );
    }

    getDiscountForCustomer(vipId: string, vipPin: string): Observable<PaymentDiscountGroupResposne | any> {
        return this._httpClient.get(`${this.apiBase}/public/discountGroup/vip?id=${vipId}&pin=${vipPin}`);
    }

    getVendorError(lang: string, data: any): Observable<VendorErrorResponseDto> {
        this._userService.lang = lang;

        let codeUrl = '';
        data.codes.forEach((code) => {
            codeUrl = codeUrl + '&codes=' + code;
        });
        const url = 'chargeBoxId=' + data.id + codeUrl + '&vendorId=' + data.vendorId + '&model=' + data.model;
        const headers = {
            'headers': new HttpHeaders().set('chargeBoxId', data.id).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };
        return this._httpClient.get(`${this.apiBase}/public/vendorErrors?${url}&page=0&size=5`, headers).pipe(
            catchError(this.handleError('getVendorError', [])),
            tap((response: any) => {
                this._vendorError.next(response);
            })
        );
    }

    initializePayment(paymentInit: InitialPaymentDto, lang: string): Observable<InitialPaymentResponseDto> {
        this._userService.lang = lang;
        const options = {
            'headers': new HttpHeaders().set('chargeBoxId', paymentInit.chargeBoxId).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        return this._httpClient
            .post(`${this.apiBase}/public/charging/initialize`, paymentInit, options)
            .pipe(
                tap((response: InitialPaymentResponseDto) => {
                    this._initPaymentData.next(response);
                    this.trackingToken = response.trackingToken;
                }));
    }

    getCurrentCB(token: string): Observable<any> {
        return this._httpClient
            .get(`${this.apiBase}/public/charging/token/details?token=${token}`)
            .pipe(
                tap((response: any) => {
                    this.idCharger = response?.chargeBoxId;
                }));
    }

    paymentVerify(): Observable<HttpStatusCode | any> {
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        const data = this._httpClient
            .post(`${this.apiBase}/public/charging/payments/verify`, this.trackingToken, options)
            .pipe(catchError(this.handleErrorPost('paymentVerify')));

        return data ? data : null;
    }

    paymentVoid(): Observable<any> {
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken)
        };

        return this._httpClient
            .post(`${this.apiBase}/public/charging/payments/cancel`, { token: this.trackingToken }, options)
            .pipe(
                tap(() => {
                    this.router.navigateByUrl(`/payment/${this.idCharger}`);
                })
            );
    }

    paymentVoidOnInit(): Promise<any> {
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken)
        };

        return firstValueFrom(this._httpClient
            .post(`${this.apiBase}/public/charging/payments/cancel`, null, options)
            .pipe(catchError(() =>
                of(null)))
        );
    }

    remoteStartTransaction(): Observable<HttpStatusCode | any> {
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        return this._httpClient
            .post(`${this.apiBase}/public/charging/transactions/start`, null, options)
            .pipe(catchError(this.handleErrorPost('remoteStartTransaction')));
        // return data ? data : null;
    }

    remoteStopTransaction(): Observable<HttpStatusCode | any> {
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        return this._httpClient
            .post(`${this.apiBase}/public/charging/transactions/stop`, this.trackingToken, options)
            .pipe(catchError(this.handleErrorPost('remoteStopTransaction')));
    }

    remoteAdminStopTransaction(token: string): Observable<HttpStatusCode | any> {
        const options = {
            'headers': new HttpHeaders().set('token', token).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        return this._httpClient
            .post(`${this.apiBase}/public/charging/transactions/stop`, this.trackingToken, options)
            .pipe(catchError(this.handleErrorPost('remoteStopTransaction')));
    }

    sendQrCodeData(): Observable<any> {
        //TODO QR COODE DISCOUNT APPLY AND SHOW
        const options = {
            'headers': new HttpHeaders().set('token', this.trackingToken).set('Accept-Language', this.selectedLang?.replace('_', '-'))
        };

        return this._httpClient
            .get(`${this.apiBase}/public/invoiceRedemption/assignDiscount`, options)
            .pipe(catchError(this.handleErrorPost('remoteStopTransaction')));
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    CheckParams(): boolean {
        return this.trackingToken ?
            false :
            true;
    }


    getCompanyByOib(oib: string): Observable<any> {
        const options = {
            'headers': new HttpHeaders().set('Ocp-Apim-Subscription-Key', 'APIKEY')
        };

        return this._httpClient.get(`https://sudreg-api.pravosudje.hr/javni/subjekt_detalji?tipIdentifikatora=oib&identifikator=${oib}&expand_relations=True`, options).pipe(
            catchError(this.handleError('getCompanyWithOibParameter', [])),
            tap((response: any) => {
                console.log(response);
            })
        );
    }

    getSupportData(chargeBoxId: string): Observable<any> {
        const headers = {
            'headers': new HttpHeaders().set('chargeBoxId', chargeBoxId)
        };

        return this._httpClient
            .get(`${this.apiBase}/public/support`, headers)
            .pipe(catchError(() => of(null)),
                tap((response: SupportUrls) => {
                    this._supportUrls.next(response);
                })
            );
    }

    getDebtData(token: string): any {
        return {
            charge: 3,
            parking: 2,
            date: '24.04.2024',
        };

        this._httpClient.get(`${this.apiBase}/public/charging/token/debt?token=${token}`)
            .pipe(tap((response: any) => { }));
    }
}
