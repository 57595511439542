import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { HandleError, HandleErrorPost, HttpErrorHandler } from 'app/core/http/http-error-handler.service';
import { Observable, catchError } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class DeleteModalService {
    private handleError: HandleError;
    private handleErrorPost: HandleErrorPost;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('DeleteModalService');
        this.handleErrorPost =
            httpErrorHandler.createHandleErrorPost('DeleteModalService');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public deleteSingleLocation(addressPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/addresses/${addressPk}`)
            .pipe(catchError(this.handleErrorPost('DeleteLocation')));
    }
    public deleteExpenseGroup(tariffGroupPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/expenseGroup/${tariffGroupPk}`)
            .pipe(catchError(this.handleErrorPost('deleteExpenseGroup')));
    }
    public deleteExpenseTariff(expenseTariffPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/expenseTariff/${expenseTariffPk}`)
            .pipe(catchError(this.handleErrorPost('deleteExpenseTariff')));
    }
    public deleteBusinessPremise(premise: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/businessPremise/${premise}`)
            .pipe(catchError(this.handleErrorPost('DeleteBusinessPremise')));
    }
    public deleteSingleChargepoint(chargeBoxPk?: number): Observable<any> {
        return this._httpClient
            .delete(
                `${this.apiBase}/chargebox/${chargeBoxPk}`
            )
            .pipe(catchError(this.handleErrorPost('deleteCharger')));
    }
    public deleteSingleCustomer(customerPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/companyAdmin/customers/${customerPk}`)
            .pipe(catchError(this.handleError('deleteCustomer', [])));
    }
    public deleteSingleCategory(categoryPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/addresses/categories/${categoryPk}`)
            .pipe(catchError(this.handleError('deleteCategory')));
    }
    public deleteSingleDiscount(discountGroupPk: number,): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/companyAdmin/discountGroup/${discountGroupPk}/delete`)
            .pipe(catchError(this.handleError('deleteCategory')));
    }
    public deleteSingleMember(userPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/companyAdmin/users/${userPk}`)
            .pipe(catchError(this.handleErrorPost('deleteUser')));
    }
    public deleteSingleTariff(tariffPk: number): Observable<any> {
        return this._httpClient
            .delete(`${this.apiBase}/priceTariffs/${tariffPk}`)
            .pipe(catchError(this.handleErrorPost('deleteTariff')));
    }
    public deleteDayOffGroup(pk: number): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/nonWorkingDayGroup/${pk}`).pipe(
            catchError(this.handleError('deleteDayOffGroup', [])));
    }
    public deleteDayOffDay(pk: number): Observable<any> {
        const req = { nonWorkingDays: [pk] };
        return this._httpClient.delete(`${this.apiBase}/nonWorkingDayGroup/nonWorkingDay/batch`, { body: req }).pipe(
            catchError(this.handleError('deleteDayOffDay', [])));
    }
    public deleteWorkHours(pk: number): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/addresses/${pk}/workingHours`)
            .pipe(catchError(this.handleErrorPost('deleteWorkHours')));
    }

    public deleteCompany(pk: number): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/companyAdmin/businessEntities/${pk}`)
            .pipe(catchError(this.handleErrorPost('deleteCompanyEntity')));
    }

    public deleteCompanyCustomer(email: string): Observable<any> {
        return this._httpClient.delete(`${this.apiBase}/companyAdmin/businessEntities/customer?email=${email}`)
            .pipe(catchError(this.handleErrorPost('deleteCompanyCustomer')));
    }
}
