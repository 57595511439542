import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { GlobalService } from './global-conf/global.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(
        private _titleService: Title,
        private _globalService: GlobalService
    ) {
        this._titleService.setTitle(this._globalService.getResource('title'));

        document.querySelector("head > link:nth-child(6)").setAttribute('href', this._globalService.getResource('favicon16'));
        document.querySelector("head > link:nth-child(7)").setAttribute('href', this._globalService.getResource('favicon32'));
    }
}