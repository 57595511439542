/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { User, UserPassword } from 'app/core/user/user.types';
import { Observable, ReplaySubject, catchError, map, of, tap } from 'rxjs';
import { HandleError, HttpErrorHandler } from '../http/http-error-handler.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);
    private userPk: number;
    private handleError: HandleError;

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private httpErrorHandler: HttpErrorHandler,
        @Inject('API_BASE_URL') private apiBase: string,
        private _translocoService: TranslocoService
    ) {
        this.handleError =
            httpErrorHandler.createHandleError('UserService');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------
    get lang(): string {
        return localStorage.getItem('language') ?? 'en-US';
    }

    set lang(language: string) {
        localStorage.setItem('language', language);
    }

    get companyLocale(): string {
        return localStorage.getItem('companyLocale') ?? 'hr_HR';
    }

    set companyLocale(companyLocale: string) {
        localStorage.setItem('companyLocale', companyLocale);
    }
    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        const ret$ = this._httpClient.get<User>(`${this.apiBase}/users/auth/user`).pipe(
            tap((user) => {
                this._user.next(user);
                this.userPk = user.userPk;
            })
        );

        return ret$;
    }

    // Set user lang from configuration at app.resolver
    setUserLang(preferredUserLang: string): Observable<any> {
        const requestModel: any = {
            value: preferredUserLang,
            path: 'general.platform.language'
        };

        return this._httpClient.patch(`${this.apiBase}/configuration`, requestModel).pipe(
            tap((lang) => {
                this.lang = preferredUserLang.replace('_', '-');
            })
        );
    }

    getUserLang(): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/configuration?path=GENERAL_PLATFORM_LANGUAGE`).pipe(
            tap((lang) => {
                this._translocoService.setActiveLang(lang.value || 'en_US');
                this.lang = lang?.value;
            })
        );
    }
    getUserGeneralCountryCompanyLocale(): Observable<any> {
        return this._httpClient.get(`${this.apiBase}/configuration?path=GENERAL_COUNTRY_LOCALE`).pipe(
            tap((companyLocale) => {
                this.companyLocale = companyLocale?.value || 'hr_HR';
            })
        );
    }

    getUserImage(): Observable<any> {
        this._user.subscribe(val =>
            this.userPk = val.userPk
        );

        const options = {
            'headers': new HttpHeaders({
                'Accept': 'image/jpeg'
            }),
            'responseType': 'blob' as 'json'
        };

        return this._httpClient.get<Blob>(`${this.apiBase}/users/${this.userPk}/image`, options).pipe(
            tap((userImg: Blob) => {
                if (userImg.size !== 0) {
                    this._user.subscribe(val =>
                        val.avatar = userImg
                    );
                }
                else {
                    this._user.subscribe(val =>
                        val.avatar = null
                    );
                }
            }),
            catchError(() => {
                this._user.subscribe(val =>
                    val.avatar = null
                );
                return of(false)
            }),
        );
    }

    postUserImage(file: any): Observable<any> {
        this._user.subscribe(val =>
            this.userPk = val.userPk
        );

        const formData = new FormData();

        formData.append('imageFile', file);
        return this._httpClient.post(`${this.apiBase}/users/${this.userPk}/image`, formData).pipe(
            catchError(this.handleError('postImgData', []))
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient
            .patch<User>(`${this.apiBase}/user`, { user })
            .pipe(
                map((response) => {
                    this._user.next(response);
                })
            );
    }

    /**
     * Change the current user password
     */
    changePassword(userPassword: UserPassword): Observable<any> {
        this._user.subscribe(val =>
            this.userPk = val.userPk
        );

        return this._httpClient
            .patch<any>(`${this.apiBase}/users/auth/password/${this.userPk}`, userPassword);
    }
}
