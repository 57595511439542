/* eslint-disable @typescript-eslint/naming-convention */

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
} from '@angular/core';
import {
    FuseNavigationService,
    FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { TranslocoService } from '@ngneat/transloco';
import { AuthService } from 'app/core/auth/auth.service';
import { UserService } from 'app/core/user/user.service';
import { take } from 'rxjs';

@Component({
    selector: 'languages',
    templateUrl: './languages.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'languages',
})
export class LanguagesComponent implements OnInit, OnDestroy {
    availableLangs: any[];
    activeLang: string;
    flagCodes: any;

    /**
     * Constructor
     */
    constructor(
        private _fuseNavigationService: FuseNavigationService,
        private _translocoService: TranslocoService,
        private _userService: UserService,
        private _authService: AuthService,
        private _changeDetectorRef: ChangeDetectorRef
    ) { }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        const host = window.location.origin;
        // Get the available languages from transloco
        this.availableLangs = this._translocoService.getAvailableLangs();

        // Subscribe to language changes
        this._translocoService.langChanges$.subscribe((activeLang) => {
            // Get the active lang
            this.activeLang = activeLang;

            // Update the navigation
            this._updateNavigation(activeLang);

            this._changeDetectorRef?.markForCheck();
        });

        if (host === 'https://evc-me.hobaca.com' || host === 'https://charge.evcmontenegro.me') {
            this.flagCodes = {
                'hr_HR': 'sr_ME',
                'en_US': 'en_US',
            };
        }
        else {
            // Set the country iso codes for languages for flags
            this.flagCodes = {
                'hr_HR': 'hr_HR',
                'en_US': 'en_US',
            };
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void { }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the active lang
     *
     * @param lang
     */
    setActiveLang(lang: string): void {
        if (this._authService.accessToken) {
            this._userService.setUserLang(lang).subscribe();
        }
        // Set the active lang
        this._translocoService.setActiveLang(lang);
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Private methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Update the navigation
     *
     * @param lang
     * @private
     */
    private _updateNavigation(lang: string): void {
        // For the demonstration purposes, we will only update the Dashboard names
        // from the navigation but you can do a full swap and change the entire
        // navigation data.
        //
        // You can import the data from a file or request it from your backend,
        // it's up to you.

        // Get the component -> navigation data -> item
        const navComponent =
            this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
                'mainNavigation'
            );

        // Return if the navigation component does not exist
        if (!navComponent) {
            return null;
        }

        // Get the flat navigation data
        const navigation = navComponent.navigation;

        // Get the Home item and update its title
        const homeItem = this._fuseNavigationService.getItem(
            'home',
            navigation
        );
        if (homeItem) {
            this._translocoService
                .selectTranslate('Home.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    homeItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Finances item and update its title
        const moneyItem = this._fuseNavigationService.getItem(
            'finance',
            navigation
        );
        if (moneyItem) {
            this._translocoService
                .selectTranslate('Finances.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    moneyItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Finances item and update its title
        const invoiceItem = this._fuseNavigationService.getItem(
            'invoice',
            navigation
        );
        if (invoiceItem) {
            this._translocoService
                .selectTranslate('Invoices.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    invoiceItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Chargebox item and update its title
        const chargeboxItem = this._fuseNavigationService.getItem(
            'chargers',
            navigation
        );
        if (chargeboxItem) {
            this._translocoService
                .selectTranslate('Chargers.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    chargeboxItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        const roamingItem = this._fuseNavigationService.getItem(
            'roaming',
            navigation
        );
        if (chargeboxItem) {
            this._translocoService
                .selectTranslate('Roaming.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    roamingItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Customers item and update its title
        const contactsItem = this._fuseNavigationService.getItem(
            'contacts',
            navigation
        );
        if (contactsItem) {
            this._translocoService
                .selectTranslate('Contact')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    contactsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Customers item and update its title
        const customersItem = this._fuseNavigationService.getItem(
            'customers',
            navigation
        );
        if (customersItem) {
            this._translocoService
                .selectTranslate('Customer.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    customersItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Customers item and update its title
        const companyItem = this._fuseNavigationService.getItem(
            'company',
            navigation
        );
        if (companyItem) {
            this._translocoService
                .selectTranslate('Company.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    companyItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Customers item and update its title
        const notifications = this._fuseNavigationService.getItem(
            'notifications',
            navigation
        );
        if (notifications) {
            this._translocoService
                .selectTranslate('Notifications.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    notifications.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Reports item and update its title
        const reportItem = this._fuseNavigationService.getItem(
            'reports',
            navigation
        );
        if (reportItem) {
            this._translocoService
                .selectTranslate('Reports')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    reportItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Maps item and update its title
        const mapsItem = this._fuseNavigationService.getItem(
            'maps',
            navigation
        );
        if (mapsItem) {
            this._translocoService
                .selectTranslate('Locations.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    mapsItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Session item and update its title
        const sessionItem = this._fuseNavigationService.getItem(
            'session',
            navigation
        );
        if (sessionItem) {
            this._translocoService
                .selectTranslate('Session.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    sessionItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }

        // Get the Price calendar item and update its title
        const priceCalednarItem = this._fuseNavigationService.getItem(
            'price-calendar',
            navigation
        );
        if (priceCalednarItem) {
            this._translocoService
                .selectTranslate('PriceCalendar.Title')
                .pipe(take(1))
                .subscribe((translation) => {
                    // Set the title
                    priceCalednarItem.title = translation;

                    // Refresh the navigation component
                    navComponent.refresh();
                });
        }
    }
}
