<mat-dialog-content *transloco="let t; read: 'Session.Details'" class="min-w-4/5 min-h-full h-full flex flex-col">
    <!-- Header -->
    <div class="p-2 flex justify-between">
        <div class="justify-start w-full">
            <div class="text-xl">{{ t('Title') }}</div>
        </div>

        <div class="flex justify-end">
            <button mat-icon-button aria-label="close dialog" mat-dialog-close>
                <mat-icon class="icon-size-5" [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
            </button>
        </div>
    </div>

    <div class="flex w-full h-full min-h-90 rounded-2xl">
        <div class="flex flex-col flex-auto shadow rounded-2xl bg-white overflow-auto">
            <div class="flex flex-col justify-around mx-6">
                <!-- Request -->
                <div class="p-3">
                    <div class="flex gap-4 pl-4 pr-4 pt-4 pb-2">
                        <span>
                            {{ t('RequestTime') }}
                        </span>

                        <span>
                            {{ data.requestTime | date: 'dd.MM.yyyy HH:mm:ss' }}
                        </span>
                    </div>

                    <div class="w-full h-fit bg-gray-100 p-4 rounded-2xl">
                        <pre [innerHTML]="data.request | prettyjson"></pre>
                    </div>
                </div>

                <!-- Response -->
                <div class="p-3">
                    <div class="flex gap-4 pl-4 pr-4 pt-4 pb-2">
                        <span>
                            {{ t('ResponseTime') }}
                        </span>

                        <span>
                            {{ data.responseTime | date: 'dd.MM.yyyy HH:mm:ss' }}
                        </span>
                    </div>

                    <div class="w-full h-fit bg-gray-100 p-4 rounded-2xl overflow-x-auto">
                        <code>
                            <pre [innerHTML]="data.response | prettyjson"></pre>
                        </code>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
