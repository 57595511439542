<!-- Button -->
<div *transloco="let t">
    <button mat-icon-button [matMenuTriggerFor]="userActions">
        <span class="relative">
            <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user.avatar" [src]="user.avatar" />
            <mat-icon *ngIf="!showAvatar || !user.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </span>
    </button>
    
    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{ t('SignedInAs') }}</span>
                <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item routerLink="/settings">
            <mat-icon [svgIcon]="'heroicons_outline:cog'"></mat-icon>
            <span>{{ t('Settings') }}</span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon [svgIcon]="'heroicons_outline:logout'"></mat-icon>
            <span>{{ t('SignOut') }}</span>
        </button>
    </mat-menu>
</div>
